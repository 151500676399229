import { Tooltip, OverlayTrigger } from "react-bootstrap";

interface Props {
    className?: string;
    block?: boolean;
    type?: "button" | "submit" | undefined | "reset";
    onClick?: () => void;
    margin?: string;
    text?: string;
    disabled?: boolean;
    tooltipTitle?: string;
    tooltipPlacement?: "top" | "bottom" | "left" | "right";
    icon?: string;
    iconFontSize?: string;
}
const renderTooltip = (tooltipTitle: any) => <Tooltip id="button-tooltip">{tooltipTitle}</Tooltip>;
export default function AppButton(props: Props) {
    const isDisabled = props.disabled ?? false;

    function getClasses() {
        let className = "";
        if (props.className) {
            className += props.className;
        } else {
            className += "btn btn-primary waves-effect w-md waves-primary";
        }
        if (props.margin) {
            className += " " + props.margin;
        }
        if (props.block) {
            className += " btn-block";
        }
        return className;
    }

    const fontSize = props.iconFontSize ?? "1.5rem";
    const button = (
        <button className={getClasses()} type={props.type || "submit"} disabled={isDisabled} onClick={props.onClick}>
            {props.icon && <i className={`mdi mdi-${props.icon}`} style={{ fontSize: fontSize }} />}
            {!props.icon && props.text}
        </button>
    );

    if (props.tooltipTitle) {
        return (
            <OverlayTrigger
                placement={props.tooltipPlacement ?? "right"}
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(props.tooltipTitle)}>
                {button}
            </OverlayTrigger>
        );
    }

    if (props.block) {
        return <div className="col-xs-12">{button}</div>;
    }
    return button;
}
