import useQueryParams from "../../../hooks/useQueryParams";
import { Alert, Card, Col, Form, Row } from "react-bootstrap";
import { IfCondition } from "react-ifloop-ts";
import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import { strings } from "../../../translation/locale";
import AppTextField from "../../../components/AppTextField";
import AppButton from "../../../components/AppButton";
import useComponentState from "../../../hooks/useComponentState";
import { useFormik } from "formik";
import * as Yup from "yup";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import ToastManager from "../../../util/ToastManager";
import AppFileField from "../../../components/AppFileField";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
	mapAuthorsToSelectOptions,
	mapCategoriesSelectedValuesToIds,
	mapCategoriesToSelectOptions,
	mapCategoriesToSelectValues,
	mapSeriesToSelectOptions,
} from "./bookDataMapper";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { appStore } from "../../../data/store/AppStore";
import parseISO from "date-fns/parseISO";
import UploadFileComponent from "./UploadFileComponent";
//import DateTimeUtil from "../../../util/DateTimeUtil";
import AppCircleFileImage from "../../../components/AppCircleFileImage";
import AppSwitch from "../../../components/AppSwitch";

import ar from "date-fns/locale/ar-SA";
registerLocale("ar", ar);

export default function AddEditBookScreen() {
	const queryParams = useQueryParams();
	const loadingInfo = useComponentState({ isLoading: false, error: null });
	const router = useHistory();
	const authorsState = useComponentState([]);
	const categoriesState = useComponentState([]);
	const seriesState = useComponentState([]);

	const bookInfoState = useComponentState({ thumbnailHttpLink: null, audioFileHttpLink: null, fileName: "" });
	const uploadFile = useComponentState(null);
	const uploadedFileRef = useRef(null);
	const formik = useFormik({
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
		initialValues: {
			title: "",
			subtitle: "",
			author: null,
			categories: [],
			summary: "",
			thumbnail: null,
			audioFile: null,
			isFree: false,
			isRecommended: false,
			youtubeId: "",
			publishDate: null,
			catOrder: 0,
			serie: null,
			publisherRatio: 0,
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().min(2, strings.formatString(strings.validation.minFieldChars, 2)).required(strings.validation.required),
			subtitle: Yup.string().min(2, strings.formatString(strings.validation.minFieldChars, 2)).optional(),
			author: Yup.mixed().required(strings.validation.required),
			categories: Yup.array().min(1, strings.formatString(strings.validation.minArray, 1)),
			summary: Yup.string().min(2, strings.formatString(strings.validation.minFieldChars, 2)).optional(),
			thumbnail: Yup.object().nullable(),
			audioFile: Yup.object().nullable(),
			publishDate: Yup.mixed().nullable(), //Yup.mixed().required(strings.validation.required),
			serie: Yup.mixed().nullable(),
			publisherRatio: Yup.number().min(0, strings.validation.minFieldChars).max(100, strings.validation.maxFieldChars).optional(),
		}),
		async onSubmit(values) {
			const request = new HttpRequest();
			let formData = new FormData();
			formData.append("isFree", values.isFree + "");
			formData.append("isRecommended", values.isRecommended + "");
			formData.append("title", values.title);
			formData.append("subtitle", values.subtitle ?? "");
			formData.append("catOrder", "0");
			formData.append("publisherRatio", values.publisherRatio + "");
			if (values.author != null) {
				// @ts-ignore
				formData.append("author", values.author.value);
			}
			if (values.serie != null) {
				// @ts-ignore
				formData.append("serie", values.serie.value);
			}
			if (values.categories.length > 0) {
				const categories = mapCategoriesSelectedValuesToIds(values.categories);
				categories.forEach((cat) => {
					formData.append("categories[]", cat);
				});
			} else {
				formData.append("categories[]", "");
			}
			formData.append("summary", values.summary ?? "");
			if (values.thumbnail != null) {
				// @ts-ignore
				formData.append("thumbnail", values.thumbnail);
			}
			if (uploadedFileRef.current != null) {
				// @ts-ignore
				formData.append("audioFile", JSON.stringify(uploadedFileRef.current));
			}
			if (values.youtubeId !== "") {
				formData.append("youtubeId", values.youtubeId);
			}
			if (values.publishDate != null) {
				// @ts-ignore
				formData.append("scheduled", values.publishDate);
			} else {
				// schedul after 14 days
				const date = new Date();
				date.setDate(date.getDate() + 14);
				// convert date to string
				formData.append("scheduled", date.toISOString());
			}
			if (queryParams.id) {
				// edit book
				request.url = `admin/v2/books/${queryParams.id}`;
				request.body = formData;
				request.method = "PUT";
			} else {
				// add new book
				request.url = `admin/v2/books`;
				request.body = formData;
				request.method = "POST";
			}

			loadingInfo.value = { isLoading: true, error: null };
			HttpService.send(request)
				.then((response) => {
					loadingInfo.value = { isLoading: false, error: null };
					ToastManager.showSuccess(response.data.message);
					router.goBack();
				})
				.catch((e) => {
					loadingInfo.value = { isLoading: false, error: null };
					ToastManager.showError(e.message);
				});
		},
	});

	useEffect(() => {
		appStore.update((state) => {
			state.showBackButton = true;
		});
	}, []);

	useEffect(() => {
		loadAuthors();
		loadCategories();
		loadSeries();
		if (queryParams.id) {
			loadBookDetails(queryParams.id);
		}
	}, [queryParams]);

	function loadBookDetails(bookId: any) {
		loadingInfo.value = { isLoading: true, error: null };
		const request = new HttpRequest();
		request.method = "GET";
		request.url = `/admin/v2/books/${bookId}`;
		HttpService.send(request)
			.then((res) => {
				loadingInfo.value = { isLoading: false, error: null };
				bookInfoState.value = {
					thumbnailHttpLink: res.data.book.thumbnail?.location,
					audioFileHttpLink: res.data.book.audioFile?.location,
					fileName: res.data.book.audioFile?.originalname,
				};

				const book = res.data.book;
				formik.setValues({
					title: book.title,
					subtitle: book.subtitle,
					// @ts-ignore
					author: { value: book.author?._id, label: book.author?.name },
					// @ts-ignore
					categories: mapCategoriesToSelectValues(book.categories),
					summary: book.summary,
					publisherRatio: book.publisherRatio ?? 0,
					thumbnail: null,
					audioFile: null,
					youtubeId: book.youtubeId ?? "",
					isFree: book.isFree ?? false,
					isRecommended: book.isRecommended ?? false,
					catOrder: book.catOrder,
					// @ts-ignore
					publishDate: book.scheduled ? parseISO(book.scheduled) : null,
					// @ts-ignore
					serie: { value: book.serie?._id, label: book.serie?.title },
				});
			})
			.catch((e) => {
				loadingInfo.value = { isLoading: false, error: e.message };
			});
	}

	function loadAuthors() {
		const request = new HttpRequest();
		request.method = "GET";
		request.url = "/admin/v2/authors";
		HttpService.send(request)
			.then((res) => {
				authorsState.value = mapAuthorsToSelectOptions(res.data.authors);
			})
			.catch((e) => {
				ToastManager.showError(e.message);
			});
	}

	function loadCategories() {
		const request = new HttpRequest();
		request.method = "GET";
		request.url = "/admin/v2/categories";
		HttpService.send(request)
			.then((res) => {
				categoriesState.value = mapCategoriesToSelectOptions(res.data.categories);
			})
			.catch((e) => {
				ToastManager.showError(e.message);
			});
	}

	function loadSeries() {
		const request = new HttpRequest();
		request.method = "GET";
		request.url = "/admin/v2/serieses";
		HttpService.send(request)
			.then((res) => {
				seriesState.value = mapSeriesToSelectOptions(res.data.serieses);
			})
			.catch((e) => {
				ToastManager.showError(e.message);
			});
	}

	return (
		<Row className={"flex-column justify-content-center"}>
			<IfCondition condition={loadingInfo.value.isLoading}>
				<Col className={"text-center"}>
					<AppLoadingSpinner />
				</Col>
			</IfCondition>
			<IfCondition condition={bookInfoState.value.loadDetailsError != null}>
				<Alert variant={"danger"}>{bookInfoState.value.loadDetailsError}</Alert>
			</IfCondition>
			<Col>
				<Card style={{ height: "85vh" }}>
					<Card.Header className={"text-center"}>{queryParams.id ? strings.edit : strings.addNew}</Card.Header>
					<Card.Body>
						<form onSubmit={formik.handleSubmit}>
							<Row>
								<Col md={6}>
									<AppTextField type={"text"} name={"title"} formik={formik} placeholder={strings.books.title} />
								</Col>
								<Col md={6}>
									<AppTextField type={"text"} name={"subtitle"} formik={formik} placeholder={strings.books.subtitle} />
								</Col>
							</Row>
							<Row>
								<Col>
									<Row>
										<Col md={10}>
											<AppFileField
												name={"audioFile"}
												placeholder={`${strings.books.audioFile}: ${bookInfoState.value.fileName}`}
												onFilePicked={(file) => {
													formik.setFieldValue("audioFile", file);
													uploadFile.value = Date.now();
												}}
												formik={formik}
											/>
										</Col>

										<IfCondition condition={bookInfoState.value.audioFileHttpLink != null}>
											<Col className={"mt-md-2rem"}>
												<AppButton
													text={strings.books.showFile}
													onClick={() => {
														window.open(bookInfoState.value.audioFileHttpLink, "_blank");
													}}
													type={"button"}
													className={"btn btn-success btn-trans waves-effect btn-sm waves-success"}
												/>
											</Col>
										</IfCondition>
									</Row>
								</Col>

								<Col>
									<Row>
										<Col>
											<AppFileField
												name={"thumbnail"}
												placeholder={strings.books.photo}
												onFilePicked={(file) => {
													formik.setFieldValue("thumbnail", file);
												}}
												formik={formik}
											/>
										</Col>
										<AppCircleFileImage
											file={formik.values.thumbnail}
											url={bookInfoState.value.thumbnailHttpLink}
											label={formik.values.title}
										/>
									</Row>
								</Col>
							</Row>
							<Row>
								<Col>
									<IfCondition condition={uploadFile.value != null}>
										<UploadFileComponent
											file={formik.values.audioFile}
											onFileUploaded={(audioFile) => {
												uploadedFileRef.current = audioFile;
											}}
											time={uploadFile.value}
										/>
									</IfCondition>
								</Col>
							</Row>

							<Row>
								<Col md={6}>
									<AppTextField type={"text"} name={"summary"} formik={formik} placeholder={strings.books.summary} as={"textarea"} rows={9} />
								</Col>
								<Col md={6}>
									<Row>
										<Col md={4}>
											<Form.Group>
												<Form.Label htmlFor={"author"}>{strings.books.author}</Form.Label>
												<Select
													options={authorsState.value}
													value={formik.values.author}
													styles={{
														menu: (provided) => ({ ...provided, zIndex: 9999 }),
													}}
													onChange={(value) => {
														formik.setFieldValue("author", value);
													}}
												/>
												<IfCondition condition={formik.errors["author"] != null}>
													<Form.Text className={"text-danger"}>{formik.errors["author"]}</Form.Text>
												</IfCondition>
											</Form.Group>
										</Col>
										<Col md={4}>
											<Form.Group>
												<Form.Label htmlFor={"category"}>{strings.books.category}</Form.Label>
												<Select
													options={categoriesState.value}
													value={formik.values.categories}
													closeMenuOnSelect={false}
													isSearchable={false}
													isClearable={false}
													styles={{
														menu: (provided) => ({ ...provided, zIndex: 9999 }),
													}}
													isMulti={true}
													onChange={(value: any) => {
														formik.setFieldValue("categories", value);
													}}
												/>
												<IfCondition condition={formik.errors["categories"] != null}>
													<Form.Text className={"text-danger"}>{formik.errors["categories"]}</Form.Text>
												</IfCondition>
											</Form.Group>
										</Col>
										<Col md={4}>
											<Form.Group>
												<Form.Label htmlFor={"serie"}>{strings.books.series}</Form.Label>
												<Select
													options={seriesState.value}
													value={formik.values.serie}
													isClearable={true}
													styles={{
														menu: (provided) => ({ ...provided, zIndex: 9999 }),
													}}
													onChange={(value: any) => {
														formik.setFieldValue("serie", value);
													}}
												/>
												<IfCondition condition={formik.errors["serie"] != null}>
													<Form.Text className={"text-danger"}>{formik.errors["serie"]}</Form.Text>
												</IfCondition>
											</Form.Group>
										</Col>
									</Row>

									<Row>
										<Col md={2}>
											<AppSwitch checked={formik.values.isFree} name={"isFree"} formik={formik} label={strings.books.free} />
										</Col>

										<Col md={6}>
											<Form.Group>
												<Form.Label className={"d-block"}>{strings.books.publishDate}</Form.Label>
												<div>
													<DatePicker
														selected={formik.values.publishDate}
														className="form-control border-bottom "
														locale="ar"
														dateFormat="dd.MM.yyyy @ hh:mm aa"
														showTimeSelect={true}
														onChange={(date, event) => {
															formik.setFieldValue("publishDate", date);
														}}
													/>
												</div>
												<IfCondition condition={formik.errors["publishDate"] != null}>
													<Form.Text className={"text-danger"}>{formik.errors["publishDate"]}</Form.Text>
												</IfCondition>
											</Form.Group>
										</Col>
										<Col md={2}>
											<AppTextField type={"number"} name={"publisherRatio"} formik={formik} placeholder={strings.books.publisherRatio} />
										</Col>
										<Col md={2}>
											<AppSwitch
												checked={formik.values.isRecommended}
												name={"isRecommended"}
												formik={formik}
												label={strings.books.isRecommended}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className="justify-content-md-center">
								<Col md={4}>
									<button className="btn btn-success btn-lg btn-block fs2rem" type="submit" disabled={loadingInfo.value.isLoading}>
										{strings.save}
									</button>
								</Col>
							</Row>
						</form>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
}
