import { useEffect, useMemo, useRef } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { IfCondition } from "react-ifloop-ts";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";

import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import AppButton from "../../../components/AppButton";
import { strings } from "../../../translation/locale";
import WarningAlertDialog from "../../../components/WarningAlertDialog";
import useComponentState from "../../../hooks/useComponentState";
import { appStore } from "../../../data/store/AppStore";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import { Series, SeriesResponse } from "../../../data/model/SeriesesResponse";
import ToastManager from "../../../util/ToastManager";
import DialogUtil from "../../../util/DialogUtil";
import ArrayUtil from "../../../util/ArrayUtil";
import AppUtil from "../../../util/AppUtil";

export default function SeriesesScreen() {
    const router = useHistory();
    const loadingInfoState = useComponentState({ isLoading: true, error: null });
    const seriesesState = useComponentState([]);
    const allSeriesesRef = useRef<Series[]>();
    const searchValueRef = useRef<string>("");
    const deleteActionState = useComponentState({ showDialog: false, series: null });

    // columns
    const columns = useMemo(() => {
        return [
            {
                name: strings.series.seriesPhoto,
                cell: (row: any) => {
                    return (
                        <Row className={"p-2"} style={{ maxWidth: "300px" }}>
                            <img
                                className="img-responsive rounded-circle"
                                style={{ width: "50px", height: "50px" }}
                                src={row.image?.location}
                                alt={row.title}
                                onClick={() => {
                                    DialogUtil.showImageDialog(row.image?.location);
                                }}
                            />
                        </Row>
                    );
                },
            },
            {
                name: strings.series.seriesTitle,
                cell: (row: any) => {
                    return row.title;
                },
            },
            /*{
                name: strings.series.seriesSummary,

                cell: (row: any) => {
                    return row.description;
                },
            },*/
            {
                name: strings.teamMembers.action,
                cell: (row: any, index: number) => {
                    return (
                        <Row key={row._id}>
                            <Col>
                                {AppUtil.isUserHaveAccess("/home/serieses/addEdit") && (
                                    <AppButton
                                        text={strings.edit}
                                        className={"btn btn-primary btn-trans waves-effect waves-primary mx-2 btn-sm"}
                                        type={"button"}
                                        onClick={() => {
                                            router.push({
                                                pathname: "/home/serieses/addEdit",
                                                search: "?id=" + row._id,
                                            });
                                        }}
                                    />
                                )}
                                {AppUtil.isUserHaveAccess("/home/serieses/delete") && (
                                    <AppButton
                                        text={strings.delete}
                                        className={"btn btn-danger btn-trans waves-effect waves-danger btn-sm"}
                                        type={"button"}
                                        onClick={() => {
                                            deleteActionState.value = { showDialog: true, series: row };
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                    );
                },
            },
        ];
    }, []);

    useEffect(() => {
        appStore.update((old) => {
            old.screenHeaderName = strings.dashboard.announcements;
            old.showBackButton = false;
        });
        loadSerieses();
    }, []);

    function loadSerieses() {
        const request = new HttpRequest();
        request.method = "GET";
        request.url = "admin/v2/serieses";
        HttpService.send(request)
            .then((res) => {
                loadingInfoState.value = { isLoading: false, error: null };
                const serieses = (res.data as SeriesResponse).serieses;
                seriesesState.value = serieses;
                allSeriesesRef.current = serieses;
            })
            .catch((e) => {
                loadingInfoState.value = { isLoading: false, error: e.message };
            });
    }

    function deleteSeries() {
        deleteActionState.value = (oldState: any) => {
            return { ...oldState, showDialog: false };
        };
        //2 call delete member api.
        const request = new HttpRequest();
        request.url = `admin/v2/serieses/${deleteActionState.value.series._id}`;
        request.method = "DELETE";
        HttpService.send(request)
            .then((res) => {
                seriesesState.value = ArrayUtil.findByIdAndRemove(deleteActionState.value.series, seriesesState.value);
                allSeriesesRef.current = ArrayUtil.findByIdAndRemove(deleteActionState.value.series, allSeriesesRef.current as any[], false);
                ToastManager.showSuccess(res.data.message);
            })
            .catch((e) => {
                ToastManager.showError(e.message);
            });
    }

    function filterSerieses(event: any) {
        const searchValue = event.target.value.trim();
        searchValueRef.current = searchValue;
        if (searchValue !== "") {
            // filter by announcment name.
            seriesesState.value = allSeriesesRef.current?.filter((series) => {
                return series.title?.includes(searchValue);
            });
        } else {
            seriesesState.value = allSeriesesRef.current;
        }
    }

    return (
        <Row className={"flex-column justify-content-center"}>
            {AppUtil.isUserHaveAccess("/home/serieses/addEdit") && (
                <Col>
                    <AppButton
                        text={strings.series.addNew}
                        onClick={() => {
                            router.push("/home/serieses/addEdit");
                        }}
                        margin={"m-2"}
                    />
                </Col>
            )}

            <IfCondition condition={loadingInfoState.value.isLoading}>
                <Col className={"text-center"}>
                    <AppLoadingSpinner />
                </Col>
            </IfCondition>

            <IfCondition condition={loadingInfoState.value.error != null}>
                <Alert variant={"danger"}>{loadingInfoState.value.error}</Alert>
            </IfCondition>

            <IfCondition condition={!loadingInfoState.value.isLoading && loadingInfoState.value.error == null}>
                <Col>
                    <Form.Control placeholder={strings.search} className={"my-2 w-25"} value={searchValueRef.current} onChange={filterSerieses} />
                    <DataTable pagination={true} paginationPerPage={10} columns={columns} data={seriesesState.value} paginationResetDefaultPage={true} />
                </Col>
            </IfCondition>
            <WarningAlertDialog
                show={deleteActionState.value.showDialog}
                onCancel={() => {
                    deleteActionState.value = (oldState: any) => {
                        return { ...oldState, showDialog: false };
                    };
                }}
                onConfirm={deleteSeries}
                title={strings.alert.sureDelete}
            />
        </Row>
    );
}
