import {useState} from "react";

export default function useComponentState<T>(state: any) {
    const [hookState, setHookState] = useState<T>(state);
    return new ComponentState(hookState, setHookState);
}

class ComponentState {

    constructor(private _value: any, private _setValue: any) {
    }

    get value(): any {
        return this._value;
    }

    set value(value: any) {
        this._setValue(value);
    }

    reverseValue() {
        this.value = (oldVal: boolean) => {
            return !oldVal;
        };
    }

    clone() {
        this.value = (oldVal: any[]) => {
            return [...oldVal];
        };
    }
}
