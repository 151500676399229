import { useEffect, useState } from "react";
import { Col, Container, Card } from "react-bootstrap";
import { IfCondition } from "react-ifloop-ts";

import useComponentState from "../../hooks/useComponentState";
import HttpService from "../../data/network/HttpService";
import HttpRequest from "../../data/network/HttpRequest";
import AppLoadingSpinner from "../../components/AppLoadingSpinner";

import { strings } from "../../translation/locale";

const FilesScreen = () => {
      const loading = useComponentState(true);
      const [files, setFiles] = useState({
            images: [],
            audios: [],
      });

      const loadFiles = () => {
            loading.value = true;
            const request = new HttpRequest();
            request.method = "GET";
            request.url = "admin/v2/files";
            HttpService.send(request)
                  .then(({ data }) => {
                        if (data.statusCode === "SUCCESS" && data.files) {
                              setFiles(data.files);
                        } else {
                              console.log("error", data.message ? data.message : data);
                        }
                  })
                  .catch((e) => {
                        console.log("error", e);
                  })
                  .finally(() => {
                        loading.value = false;
                  });
      };
      useEffect(() => {
            loadFiles();
      }, []);

      const moveImagesToAws = () => {
            loading.value = true;
            const request = new HttpRequest();
            request.method = "POST";
            request.url = "admin/v2/files/move-images-to-aws";
            request.body = {
                  images: files.images,
            };
            HttpService.send(request)
                  .then(({ data }) => {
                        if (data.statusCode === "SUCCESS") {
                              if (data.message) {
                                    alert(data.message);
                              }
                              loadFiles();
                        } else {
                              console.log("error", data.message ? data.message : data);
                        }
                  })
                  .catch((e) => {
                        console.log("error", e);
                  })
                  .finally(() => {
                        loading.value = false;
                  });
      };

      const moveAudiosToAws = () => {
            loading.value = true;
            const request = new HttpRequest();
            request.method = "POST";
            request.url = "admin/v2/files/move-audios-to-aws";
            request.body = {
                  audios: files.audios,
            };
            HttpService.send(request)
                  .then(({ data }) => {
                        if (data.statusCode === "SUCCESS") {
                              if (data.message) {
                                    alert(data.message);
                              }
                              loadFiles();
                        } else {
                              console.log("error", data.message ? data.message : data);
                        }
                  })
                  .catch((e) => {
                        console.log("error", e);
                  })
                  .finally(() => {
                        loading.value = false;
                  });
      };

      return (
            <Container>
                  <Card>
                        <Card.Header className={"text-center"}>{strings.dashboard.filesManagment}</Card.Header>
                        <Card.Body>
                              <IfCondition condition={loading.value}>
                                    <Col className={"text-center"}>
                                          <AppLoadingSpinner />
                                    </Col>
                              </IfCondition>
                              <IfCondition condition={!loading.value}>
                                    <div className="d-flex justify-content-around audiohat-files">
                                          <button
                                                type="button"
                                                className="btn btn-dark"
                                                disabled={loading.value || files.images.length === 0}
                                                onClick={moveImagesToAws}>
                                                <i className="mdi mdi-file-image" />
                                                <br />
                                                {files.images.length} صورة
                                          </button>

                                          <button
                                                type="button"
                                                className="btn btn-dark"
                                                disabled={loading.value || files.audios.length === 0}
                                                onClick={moveAudiosToAws}>
                                                <i className="mdi mdi-audiobook" />
                                                <br />
                                                {files.audios.length} ملف صوتي أو فيديو
                                          </button>
                                    </div>
                              </IfCondition>
                        </Card.Body>
                  </Card>
            </Container>
      );
};

export default FilesScreen;
