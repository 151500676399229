import React, { useEffect, useMemo } from "react";
import { appStore } from "../../../data/store/AppStore";
import { strings } from "../../../translation/locale";
import useComponentState from "../../../hooks/useComponentState";
import HttpService from "../../../data/network/HttpService";
import HttpRequest from "../../../data/network/HttpRequest";
import ToastManager from "../../../util/ToastManager";
import { TeamMembersResponse } from "../../../data/model/TeamMembersResponse";
import { Col, Row } from "react-bootstrap";
import { IfCondition } from "react-ifloop-ts";
import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import DataTable from "react-data-table-component";
import WarningAlertDialog from "../../../components/WarningAlertDialog";
import ExpandRowComponent from "./ExpandRowComponent";
import ArrayUtil from "../../../util/ArrayUtil";
import AppButton from "../../../components/AppButton";
import { useHistory } from "react-router-dom";
import OrderInput from "./OrderInput";
import DialogUtil from "../../../util/DialogUtil";
import AppUtil from "../../../util/AppUtil";

export default function TeamMembersScreen() {
	const loading = useComponentState(true);
	const teamMembers = useComponentState([]);
	const deleteActionState = useComponentState({ showDialog: false, member: null });
	const router = useHistory();
	const columns = useMemo(() => {
		return [
			{
				name: strings.teamMembers.name,
				cell: (row: any) => {
					return (
						<Row
							className={"p-2"}
							onClick={() => {
								DialogUtil.showImageDialog(row.avatar?.location);
							}}
						>
							<img
								className="img-responsive rounded-circle"
								style={{ width: "50px", height: "50px" }}
								src={row.avatar?.location}
								alt={row.name}
							/>
							<h4 className={"mx-2"}>{row.name}</h4>
						</Row>
					);
				},
			},
			{
				name: strings.teamMembers.bio,
				cell: (row: any) => {
					return <h5 className={"max-lines"}>{row.bio}</h5>;
				},
			},
			{
				name: strings.teamMembers.order,
				cell: (row: any) => {
					return <OrderInput order={row.order} id={row._id} />;
				},
			},
			{
				name: strings.teamMembers.action,
				cell: (row: any, index: number) => {
					return (
						<Row key={row._id}>
							<Col>
								{AppUtil.isUserHaveAccess("/home/teamMembers/addEdit") && (
									<AppButton
										text={strings.edit}
										className={"btn btn-primary btn-trans waves-effect waves-primary mx-2 btn-sm"}
										type={"button"}
										onClick={() => {
											router.push({ pathname: "/home/teamMembers/addEdit", search: "?id=" + row._id });
										}}
									/>
								)}
								{AppUtil.isUserHaveAccess("/home/teamMembers/delete") && (
									<AppButton
										text={strings.delete}
										className={"btn btn-danger btn-trans waves-effect waves-danger btn-sm"}
										type={"button"}
										onClick={() => {
											deleteActionState.value = { member: row, showDialog: true };
										}}
									/>
								)}
							</Col>
						</Row>
					);
				},
			},
		];
	}, []);

	function loadTeamMembers() {
		loading.value = true;
		const request = new HttpRequest();
		request.method = "GET";
		request.url = "admin/v2/teamMembers";
		HttpService.send(request)
			.then((response) => {
				loading.value = false;
				teamMembers.value = (response.data as TeamMembersResponse).teamMembers;
			})
			.catch((e) => {
				loading.value = false;
				ToastManager.showError(e.message);
			});
	}

	function deleteTeamMember() {
		// 1 hide delete dialog
		deleteActionState.value = (oldState: any) => {
			return { ...oldState, showDialog: false };
		};
		//2 call delete member api.
		const request = new HttpRequest();
		request.url = `admin/v2/teamMembers/${deleteActionState.value.member._id}`;
		request.method = "DELETE";
		HttpService.send(request)
			.then((res) => {
				teamMembers.value = ArrayUtil.findByIdAndRemove(deleteActionState.value.member, teamMembers.value as any[]);
				ToastManager.showSuccess(res.data.message);
			})
			.catch((e) => {
				ToastManager.showError(e.message);
			});
	}

	useEffect(() => {
		appStore.update((s) => {
			s.screenHeaderName = strings.dashboard.teamMembers;
			s.showBackButton = false;
		});
		loadTeamMembers();
	}, []);

	return (
		<Row className={"flex-column justify-content-center"}>
			{AppUtil.isUserHaveAccess("/home/teamMembers/addEdit") && (
				<Col>
					<AppButton
						text={strings.teamMembers.addNew}
						onClick={() => {
							router.push("/home/teamMembers/addEdit");
						}}
						margin={"m-2"}
					/>
				</Col>
			)}
			<IfCondition condition={loading.value}>
				<Col className={"text-center"}>
					<AppLoadingSpinner />
				</Col>
			</IfCondition>
			<IfCondition condition={!loading.value}>
				<Col>
					<DataTable
						pagination={true}
						paginationPerPage={10}
						columns={columns}
						data={teamMembers.value}
						expandableRows={true}
						expandableRowsComponent={ExpandRowComponent}
						responsive={true}
					/>
				</Col>
			</IfCondition>
			<WarningAlertDialog
				show={deleteActionState.value.showDialog}
				onCancel={() => {
					deleteActionState.value = (oldState: any) => {
						return { ...oldState, showDialog: false };
					};
				}}
				onConfirm={deleteTeamMember}
				title={strings.alert.sureDelete}
			/>
		</Row>
	);
}
