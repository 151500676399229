import useQueryParams from "../../../hooks/useQueryParams";
import {Alert, Card, Col, Row} from "react-bootstrap";
import {IfCondition} from "react-ifloop-ts";
import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import {strings} from "../../../translation/locale";
import AppTextField from "../../../components/AppTextField";
import AppButton from "../../../components/AppButton";
import useComponentState from "../../../hooks/useComponentState";
import {useFormik} from "formik";
import * as Yup from "yup";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import ToastManager from "../../../util/ToastManager";
import AppFileField from "../../../components/AppFileField";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {appStore} from "../../../data/store/AppStore";

export default function AddEditTeamMemberScreen() {
    const queryParams = useQueryParams();
    const loading = useComponentState(false);
    const router = useHistory();
    const teamMemberData = useComponentState({name: "", bio: "", avatar: "", photo: null, loadDetailsError: null});

    function loadTeamMemberDetails(memberId: any) {
        loading.value = true;
        const request = new HttpRequest();
        request.method = "GET";
        request.url = `admin/v2/teamMembers/${memberId}`;
        HttpService.send(request).then((res) => {
            loading.value = false;
            teamMemberData.value = {
                name: res.data.teamMember.name, bio: res.data.teamMember.bio, avatar: res.data.teamMember.avatar,
                photo: null, loadDetailsError: null
            };
        }).catch((e) => {
            loading.value = false;
            teamMemberData.value = ((old: any) => {
                old.loadDetailsError = e.message;
                return {...old};
            })
        });
    }

    useEffect(() => {
        appStore.update((store)=>{
           store.showBackButton = true;
        });
        if (queryParams.id) {
            // load user details.
            loadTeamMemberDetails(queryParams.id);
        }
    }, [queryParams]);
    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        initialValues: {
            name: teamMemberData.value.name,
            bio: teamMemberData.value.bio
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().min(1, strings.formatString(strings.validation.minFieldChars, 2)).required(strings.validation.required)
        }),
        async onSubmit(values) {
            // call web service.
            const request = new HttpRequest();
            let formData = new FormData();
            if (values.name) {
                formData.append("name", values.name);
            }
            if (values.bio) {
                formData.append("bio", values.bio);
            }
            if (teamMemberData.value.photo != null) {
                formData.append("avatar", teamMemberData.value.photo);
            }
            if (queryParams.id) {
                // edit team member
                request.url = `admin/v2/teamMembers/${queryParams.id}`;
                request.body = formData;
                request.method = 'PUT';
                loading.value = true;
            } else {
                // add new team member.
                request.url = `admin/v2/teamMembers`;
                request.body = formData;
                request.method = 'POST';
                loading.value = true;
            }

            HttpService.send(request).then((response) => {
                loading.value = false;
                ToastManager.showSuccess(response.data.message);
                router.push("/home/teamMembers");
            }).catch((e) => {
                loading.value = false;
                ToastManager.showError(e.message);
            });
        },
    });

    return <Row className={"flex-column justify-content-center"}>
        <IfCondition condition={loading.value}>
            <Col className={'text-center'}><AppLoadingSpinner/></Col>
        </IfCondition>
        <IfCondition condition={teamMemberData.value.loadDetailsError != null}>
            <Alert variant={'danger'}>{teamMemberData.value.loadDetailsError}</Alert>
        </IfCondition>
        <Col>
            <Card>
                <Card.Header className={"text-center"}>
                    {queryParams.id ? strings.edit : strings.addNew}
                </Card.Header>
                <Card.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <AppTextField type={'text'} name={'name'} formik={formik} placeholder={strings.teamMembers.name}/>
                        <AppTextField type={'text'} name={'bio'} formik={formik} placeholder={strings.teamMembers.bio} as={'textarea'} rows={3}/>

                        <IfCondition condition={teamMemberData.value.avatar}>
                            <img className="img-responsive rounded-circle" style={{width: "80px", height: "80px"}}
                                 src={teamMemberData.value.avatar?.location} alt={teamMemberData.value.name}/>
                        </IfCondition>

                        <AppFileField name={'avatar'} placeholder={strings.teamMembers.photo} onFilePicked={(file) => {
                            teamMemberData.value = ((old: any) => {
                                old.photo = file;
                                return {...old};
                            });
                        }}/>
                        <AppButton text={strings.save} type={'submit'} disabled={loading.value}/>
                    </form>
                </Card.Body>
            </Card>
        </Col>
    </Row>
}