import UserCache from "../data/cache/UserCache";

export default class AppUtil {

    static kFormatter(number: number) {
        if( Math.abs(number) > 999){
         return   (Math.sign(number) * ((Math.abs(number) / 1000))).toFixed(0) + ' k';
        }
       return  (Math.sign(number) * Math.abs(number)).toFixed(0);
    }

    static isUserHaveAccess(path: string): boolean {
        const user = UserCache.getUser();
        if(!user) {
            return false;
        }

        const userCaps = user?.caps ? user.caps : [];
        const protectedEmails = ["audiohatchannel@gmail.com", "ibo2001@gmail.com", "marwan.hefny@gmail.com"];

        if (protectedEmails.includes(user.email) || userCaps.includes(path) || userCaps.includes(`${path}/`)) {
            return true;
        }

        return false;
    }
}