export const mapAuthorsToSelectOptions = (authors: any[]) => {
    return authors.map(author => {
        return {
            value: author._id,
            label: author.name
        }
    });
}
// series
export const mapSeriesToSelectOptions = (series: any[]) => {
    return series.map(serie => {
        return {
            value: serie._id,
            label: serie.title
        }
    });
}
export const mapCategoriesToSelectOptions = (categories: any[]) => {
    return categories.map(category => {
        return {
            value: category._id,
            label: category.label
        }
    });
}

export const mapCategoriesToSelectValues = (categories: any[]) => {
    return categories.map(category => {
        return {
            value: category._id,
            label: category.label
        }
    });
}

export const mapCategoriesSelectedValuesToIds = (categories: any[]) => {
    return categories.map(category => {
        return category.value;
    });
}

