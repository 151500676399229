import useComponentState from "../../../hooks/useComponentState";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import { strings } from "../../../translation/locale";
import { Col, Row } from "react-bootstrap";
import AppButton from "../../../components/AppButton";
import { IfCondition } from "react-ifloop-ts";
import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import DataTable from "react-data-table-component";
import WarningAlertDialog from "../../../components/WarningAlertDialog";
import { appStore } from "../../../data/store/AppStore";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import ToastManager from "../../../util/ToastManager";
import { CategoriesResponse } from "../../../data/model/CategoriesResponse";
import ArrayUtil from "../../../util/ArrayUtil";
import DialogUtil from "../../../util/DialogUtil";
import AppUtil from "../../../util/AppUtil";

export default function CategoriesScreen() {
	const loadingInfoState = useComponentState({ isLoading: true, error: null });
	const categoriesState = useComponentState([]);
	const router = useHistory();
	const deleteActionState = useComponentState({ showDialog: false, category: null });
	const columns = useMemo(() => {
		return [
			{
				name: strings.categories.label,
				cell: (row: any) => {
					return <Link to={"/home/books?categoryId=" + row._id}>{row.label}</Link>;
				},
			},
			{
				name: strings.categories.icon,
				cell: (row: any) => {
					return (
						<img
							className="img-responsive rounded-circle my-2"
							style={{ width: "50px", height: "50px" }}
							onClick={() => {
								DialogUtil.showImageDialog(row.icon?.location);
							}}
							src={row.icon?.location}
							alt={row.label}
						/>
					);
				},
			},
			{
				name: strings.teamMembers.action,
				cell: (row: any) => {
					return (
						<Row key={row._id}>
							<Col>
								{AppUtil.isUserHaveAccess("/home/categories/addEdit") && (
									<AppButton
										text={strings.edit}
										className={"btn btn-primary btn-trans waves-effect waves-primary mx-2 btn-sm"}
										type={"button"}
										onClick={() => {
											router.push({
												pathname: "/home/categories/addEdit",
												search: "?id=" + row._id,
											});
										}}
									/>
								)}
								{AppUtil.isUserHaveAccess("/home/categories/delete") && (
									<AppButton
										text={strings.delete}
										className={"btn btn-danger btn-trans waves-effect waves-danger btn-sm"}
										type={"button"}
										onClick={() => {
											deleteActionState.value = { showDialog: true, category: row };
										}}
									/>
								)}
							</Col>
						</Row>
					);
				},
			},
		];
	}, []);

	function deleteCategory() {
		// 1 hide delete dialog
		deleteActionState.value = (oldState: any) => {
			return { ...oldState, showDialog: false };
		};
		// call api.
		const request = new HttpRequest();
		request.method = "DELETE";
		request.url = `/admin/v2/categories/${deleteActionState.value.category._id}`;
		HttpService.send(request)
			.then((res) => {
				categoriesState.value = ArrayUtil.findByIdAndRemove(deleteActionState.value.category, categoriesState.value);
				ToastManager.showSuccess(res.data.message);
			})
			.catch((e) => {
				ToastManager.showError(e.message);
			});
	}

	function loadCategories() {
		loadingInfoState.value = { isLoading: true, error: null };
		const request = new HttpRequest();
		request.method = "GET";
		request.url = "/admin/v2/categories";
		HttpService.send(request)
			.then((res) => {
				loadingInfoState.value = { isLoading: false, error: null };
				categoriesState.value = (res.data as CategoriesResponse).categories;
			})
			.catch((e) => {
				loadingInfoState.value = { isLoading: false, error: e.message };
				ToastManager.showError(e.message);
			});
	}

	useEffect(() => {
		appStore.update((old) => {
			old.screenHeaderName = strings.dashboard.categories;
			old.showBackButton = false;
		});

		loadCategories();
	}, []);

	return (
		<Row className={"flex-column justify-content-center"}>
			{AppUtil.isUserHaveAccess("/home/categories/addEdit") && (
				<Col>
					<AppButton
						text={strings.categories.addNew}
						onClick={() => {
							router.push("/home/categories/addEdit");
						}}
						margin={"m-2"}
					/>
				</Col>
			)}
			<IfCondition condition={loadingInfoState.value.isLoading}>
				<Col className={"text-center"}>
					<AppLoadingSpinner />
				</Col>
			</IfCondition>
			<IfCondition condition={!loadingInfoState.value.isLoading && loadingInfoState.value.error == null}>
				<Col>
					<DataTable pagination={true} paginationPerPage={10} columns={columns} data={categoriesState.value} />
				</Col>
			</IfCondition>
			<WarningAlertDialog
				show={deleteActionState.value.showDialog}
				onCancel={() => {
					deleteActionState.value = (oldState: any) => {
						return { ...oldState, showDialog: false };
					};
				}}
				onConfirm={deleteCategory}
				title={strings.alert.sureDelete}
			/>
		</Row>
	);
}
