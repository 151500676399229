import React from "react";
import { Modal, Card } from "react-bootstrap";
import { User } from "../../data/model/UsersResponse";
import { strings } from "../../translation/locale";

interface Props {
	user: User | undefined;
	show?: boolean;
	handleClose?: () => void;
}

export default class WpInfoModal extends React.Component<Props> {
	renderSubscriptionInfoFromWP(user: User | undefined) {
		if (user === undefined) {
			return null;
		}
		if (!user.wpData) return "";
		const { message, data } = user.wpData;
		if (message !== "Found") {
			return <p>{strings.users.userNotFound}</p>;
		}
		if (!data || data.length <= 0) {
			return <p>{strings.users.userNotFound}</p>;
		}

		return data.map((item: any) => (
			<small key={item.id}>
				<br />
				{strings.users.order}: {item.id}
				<br />
				{strings.users.status}: {item.status}
				<br />
				{strings.users.latestPayment}: {item.last_payment}
				<br />
				{strings.users.nextPayment}: {item.next_payment}
				<br />
				{strings.users.paymentMethod}: {item.payment_method}
				<br />
				{strings.users.subscription}:
				<br />
				{item.products.map((product: any, index: number) => (
					<span key={index}>
						{product.name} {product.price} {item.currency}
					</span>
				))}
			</small>
		));
	}

	render() {
		const { show, user, handleClose } = this.props;

		return (
			<Modal
				show={show}
				backdrop="static"
				centered={true}
				keyboard={false}
				onHide={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Modal.Header closeButton>
					<Modal.Title>{strings.users.subscriptionInfo}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card>
						<Card.Body style={{ textAlign: "right" }}>{this.renderSubscriptionInfoFromWP(user)}</Card.Body>
					</Card>
				</Modal.Body>
				<Modal.Footer className="justify-content-center"></Modal.Footer>
			</Modal>
		);
	}
}
