import AppCache from "./AppCache";
import {Admin} from "../model/AdminLoginResponse";

export default class UserCache {


    static saveUser(admin: Admin, saveInCache: boolean = true) {
        AppCache.getInstance().save("admin", admin, saveInCache);
    }

    static getUser(): Admin | null {
        const admin = AppCache.getInstance().get("admin");
        if (admin) {
            return admin as Admin;
        }
        return null;
    }

    static hasUser(): boolean {
        return AppCache.getInstance().has("admin");
    }
}