import moment from "moment";
//import 'moment/locale/ar';
//moment().locale('ar')

export default class DateTimeUtil {

    static format(date: any, format = "YYYY-MM-DD h:m a") {
        if (format) {
            return moment(date).format(format);
        }
        return moment(date);
    }

    static getCurrentDateTime(): Date {
        return moment().toDate();
    }

    static isBeforeNow(publishDate: any) {
        return moment(publishDate).isBefore(moment());
    }
}