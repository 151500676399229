import useQueryParams from "../../../hooks/useQueryParams";
import { Alert, Card, Col, Row, Form } from "react-bootstrap";
import { IfCondition } from "react-ifloop-ts";
import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import { strings } from "../../../translation/locale";
import AppTextField from "../../../components/AppTextField";
import AppButton from "../../../components/AppButton";
import useComponentState from "../../../hooks/useComponentState";
import { useFormik } from "formik";
import * as Yup from "yup";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import ToastManager from "../../../util/ToastManager";
import AppFileField from "../../../components/AppFileField";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { appStore } from "../../../data/store/AppStore";
import DatePicker, { registerLocale } from "react-datepicker";

import ar from "date-fns/locale/ar-DZ";
registerLocale("ar", ar);

export default function AddEditAnnouncementScreen() {
    const queryParams = useQueryParams();
    const loading = useComponentState(false);
    const router = useHistory();
    const announcementInfoState = useComponentState({ image:{} });

    function loadAnnouncementDetails(announcementId: any) {
        loading.value = true;
        const request = new HttpRequest();
        request.method = "GET";
        request.url = `admin/v2/announcements/${announcementId}`;
        HttpService.send(request)
            .then((res) => {
                loading.value = false;
                announcementInfoState.value = {
                    title: res.data.announcement.title,
                    url: res.data.announcement.url,
                    image: res.data.announcement.image,
                    scheduled: res.data.announcement.scheduled,
                };
            })
            .catch((e) => {
                loading.value = false;
                announcementInfoState.value = (old: any) => {
                    old.loadDetailsError = e.message;
                    return { ...old };
                };
            });
    }

    useEffect(() => {
        appStore.update((old) => {
            old.showBackButton = true;
        });

        if (queryParams.id) {
            // load announcement details.
            loadAnnouncementDetails(queryParams.id);
        }
    }, [queryParams]);
    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        initialValues: {
            title: announcementInfoState.value.title,
            url: announcementInfoState.value.url,
            publishDate: null,
            image:announcementInfoState.value.image
        },
        validationSchema: Yup.object().shape({
            image:
                queryParams.id == null
                    ? Yup.mixed().test("file", strings.validation.required, (value) => {
                          return value != null;
                      })
                    : Yup.object().nullable(),
        }),
        async onSubmit(values) {
            // call web service.
            const request = new HttpRequest();
            let formData = new FormData();
            if (values.title) {
                formData.append("title", values.title);
            }
            if (values.url) {
                formData.append("url", values.url);
            }
            if (values.publishDate != null) {
                // @ts-ignore
                formData.append("scheduled", values.publishDate);
            }
            if (values.image != null) {
                formData.append("image", values.image);
            }
            if (queryParams.id) {
                // edit announcement
                request.url = `admin/v2/announcements/${queryParams.id}`;
                request.body = formData;
                request.method = "PUT";
                loading.value = true;
            } else {
                // add new announcement.
                request.url = `admin/v2/announcements`;
                request.body = formData;
                request.method = "POST";
                loading.value = true;
            }

            HttpService.send(request)
                .then((response) => {
                    loading.value = false;
                    ToastManager.showSuccess(response.data.message);
                    router.push("/home/announcements");
                })
                .catch((e) => {
                    loading.value = false;
                    ToastManager.showError(e.message);
                });
        },
    });

    return (
        <Row className={"flex-column justify-content-center"}>
            <IfCondition condition={loading.value}>
                <Col className={"text-center"}>
                    <AppLoadingSpinner />
                </Col>
            </IfCondition>
            <IfCondition condition={announcementInfoState.value.loadDetailsError != null}>
                <Alert variant={"danger"}>{announcementInfoState.value.loadDetailsError}</Alert>
            </IfCondition>
            <Col>
                <Card>
                    <Card.Header className={"text-center"}>{queryParams.id ? strings.edit : strings.addNew}</Card.Header>
                    <Card.Body>
                        <form onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col>
                                    <IfCondition condition={announcementInfoState.value.image}>
                                        <img
                                            className="img-responsive"
                                            style={{ width: "100%", height: "auto" }}
                                            src={announcementInfoState.value.image?.location}
                                            alt={announcementInfoState.value.title}
                                        />
                                    </IfCondition>
                                </Col>
                                <Col>
                                    <Row>
                                        <AppFileField
                                            name={"image"}
                                            placeholder={strings.announcements.announcmentPoster}
                                            onFilePicked={(file) => {
                                                announcementInfoState.value = ((old: any) => {
                                                    old.image.location = URL.createObjectURL(file);
                                                    return {...old};
                                                });
                                                formik.setFieldValue("image", file);
                                            }}
                                            formik={formik}
                                        />
                                    </Row>
                                    <Row>
                                        <Form.Group>
                                            <Form.Label className={"d-block"}>{strings.announcements.publishDate}</Form.Label>
                                            <div>
                                                <DatePicker
                                                    selected={formik.values.publishDate}
                                                    dateFormat="Pp"
                                                    locale="ar"
                                                    className="form-control border-bottom "
                                                    showTimeSelect={true}
                                                    onChange={(date, event) => {
                                                        formik.setFieldValue("publishDate", date);
                                                    }}
                                                />
                                            </div>
                                            <IfCondition condition={formik.errors["publishDate"] != null}>
                                                <Form.Text className={"text-danger"}>{formik.errors["publishDate"]}</Form.Text>
                                            </IfCondition>
                                        </Form.Group>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <AppTextField type={"text"} name={"title"} formik={formik} placeholder={strings.announcements.title} />
                                </Col>

                                <Col>
                                    <AppTextField type={"text"} name={"url"} formik={formik} placeholder={strings.announcements.url} />
                                </Col>
                            </Row>

                            <AppButton text={strings.save} type={"submit"} disabled={loading.value} />
                        </form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}
