export default class UserService {


    static async getSubscriptionFromWP(userEmail: string) {
        return fetch(`https://audiohatdar.com/wp-json/audiohat/v1/check_wcs?email=${userEmail}`, {
            method: "GET",
            redirect: "follow"
        })
            .then((response) => response.text())
            .then((result) => JSON.parse(result))
            .catch((error) => console.log("error", error));
    }

}