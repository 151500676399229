import Pagination from "react-responsive-pagination";
import { useEffect, useRef, useState } from "react";
import { appStore } from "../../data/store/AppStore";
import { strings } from "../../translation/locale";
import useComponentState from "../../hooks/useComponentState";
import { Alert, Col, Form, Row, Table } from "react-bootstrap";
import { ForLoop, IfCondition, IfElseCondition } from "react-ifloop-ts";
import AppLoadingSpinner from "../../components/AppLoadingSpinner";
import { User, UsersResponse } from "../../data/model/UsersResponse";
import DateTimeUtil from "../../util/DateTimeUtil";
import HttpRequest from "../../data/network/HttpRequest";
import HttpService from "../../data/network/HttpService";
import { useDebounce } from "use-debounce";
import EditSubscription from "./EditSubscription";
import DialogUtil from "../../util/DialogUtil";
import AppButton from "../../components/AppButton";
import UserService from "./UserService";
import AppUtil from "../../util/AppUtil";

import WpInfoModal from "./WpInfoModal";
import SendNotificationModal from "./SendNotificationModal";

export default function UsersScreen() {
  const loadingState = useComponentState({ isLoading: true, error: null });
  const usersRef = useRef<any>({ limit: 50, users: [], total: 0, currentPage: 1, totalPages: 0 });
  const searchFiledState = useComponentState("");
  const [searchText] = useDebounce(searchFiledState.value, 1000);
  const refreshState = useComponentState(false);
  const usersCountRef = useRef(0);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [showWpInfo, setShowWpInfo] = useState(false);
  const [showSendNotification, setShowSendNotification] = useState(false);

  const [notification, setNotification] = useState({ content: "", url: "" });

  useEffect(() => {
    appStore.update((state) => {
      state.screenHeaderName = strings.dashboard.users;
    });
  }, []);

  useEffect(() => {
    usersRef.current = { limit: 50, offset: 0, total: 0, currentPage: 1, totalPages: 0, users: [] };
    loadUsers();
  }, [searchText]);

  function filterUsers(event: any) {
    searchFiledState.value = event.target.value;
  }

  function loadMoreUsers(pageNumber: number) {
    usersRef.current.currentPage = pageNumber;
    loadUsers();
  }

  function loadUsers() {
    loadingState.value = { isLoading: true, error: null };
    const request = new HttpRequest();
    request.method = "GET";
    request.url = "/admin/v2/users";
    let offset = 0;
    if (usersRef.current.currentPage > 1) {
      offset = (usersRef.current.currentPage - 1) * usersRef.current.limit;
    }
    let query: any = { offset: offset, limit: usersRef.current.limit };
    if (searchText !== "") {
      query["q"] = searchText;
    }
    request.queryParams = query;

    HttpService.send(request)
      .then((res) => {
        const usersResults = (res.data as UsersResponse).usersResults;

        usersRef.current.users = usersResults.users;
        if (usersResults.total > -1) {
          usersRef.current.total = usersResults.total;
          usersRef.current.totalPages = Math.round(usersResults.total / usersRef.current.limit);
          if (usersCountRef.current === 0) {
            usersCountRef.current = usersResults.total;
            appStore.update((state) => {
              state.screenHeaderName = strings.dashboard.users + " (" + usersResults.total + ") " + strings.user;
            });
          }
        }

        loadingState.value = { isLoading: false, error: null };
      })
      .catch((e) => {
        loadingState.value = { isLoading: false, error: e.message };
      });
  }

  function sendToRevenuecat(user: User) {
    if (window.confirm(strings.areYouSure)) {
      loadingState.value = { isLoading: true, error: null };

      const request = new HttpRequest();
      request.method = "POST";
      request.url = `/admin/v2/users/${user._id}/revenuecat`;
      request.body = { activeUntil: user.activeUntil };
      HttpService.send(request)
        .then(({ data }) => {
          loadingState.value = { isLoading: false, error: null };
          alert(data.result.message);
        })
        .catch((e) => {
          loadingState.value = { isLoading: false, error: e.message };
          alert(e.message);
        });
    }
  }
  function loadUserInfoFromWP(user: User) {
    loadingState.value = { isLoading: true, error: null };
    UserService.getSubscriptionFromWP(user.email)
      .then((res) => {
        user.wpData = res;
        loadingState.value = { isLoading: false, error: null };
        setSelectedUser(user);
        setShowWpInfo(true);
      })
      .catch((e) => {
        loadingState.value = { isLoading: false, error: e.message };
        setSelectedUser(undefined);
        setShowWpInfo(false);
      });
  }
  function syncUserInfoFromWP(user: User) {
    loadingState.value = { isLoading: true, error: null };
    const request = new HttpRequest();
    request.method = "PUT";
    request.url = `/admin/v2/users/sync-user-data`;
    request.body = { email: user.email };
    HttpService.send(request)
      .then(({ data }) => {
        loadingState.value = { isLoading: false, error: null };
        usersRef.current.users = usersRef.current.users.map((u: User) => {
          if (u._id === user._id) {
            return data.result;
          }
          return u;
        });
        refreshState.reverseValue();
      })
      .catch((e) => {
        loadingState.value = { isLoading: false, error: e.message };
        alert(e.message);
      })
      .finally(() => {
        loadingState.value = { isLoading: false, error: null };
      });
  }

  function deleteTheUserAccount(user: User) {
    if (window.confirm(strings.users.sureToDeleteUser + " " + user.email + "?")) {
      loadingState.value = { isLoading: true, error: null };
      const request = new HttpRequest();
      request.method = "DELETE";
      request.url = `/admin/v2/users/${user._id}`;
      HttpService.send(request)
        .then(({ data }) => {
          loadingState.value = { isLoading: false, error: null };
          alert(data.message);
          loadUsers();
        })
        .catch((e) => {
          loadingState.value = { isLoading: false, error: e.message };
          alert(e.message);
        });
    }
  }

  function changeUserPassword(user: User, newPassword: string) {
    loadingState.value = { isLoading: true, error: null };
    const request = new HttpRequest();
    request.method = "PUT";
    request.url = "/admin/v2/users/" + user._id + "/update_password";
    request.body = { newPassword: newPassword };
    HttpService.send(request)
      .then((res) => {
        loadingState.value = { isLoading: false, error: null };
        if (res.data.message) {
          alert(res.data.message);
        } else {
          alert("خطأ غير معروف");
        }
      })
      .catch((e) => {
        loadingState.value = { isLoading: false, error: e.message };
        alert(e.message);
      });
  }

  function sendNotification() {
    if (selectedUser === undefined) {
      window.alert(strings.users.missingUser);
      return;
    }
    if (notification.content === "") {
      window.alert(strings.users.notificationContentIdRequired);
      return;
    }

    setShowSendNotification(false);
    loadingState.value = { isLoading: true, error: null };
    const request = new HttpRequest();
    request.method = "POST";
    request.url = `/admin/v2/notifications`;
    request.body = {
      content: notification.content,
      url: notification.url,
      type: "Info",
      toUserId: selectedUser._id,
      fromAudiohat: true,
    };
    HttpService.send(request)
      .then(({ data }) => {
        loadingState.value = { isLoading: false, error: null };
        alert(data.message || strings.users.notificationSent);
        setSelectedUser(undefined);
        setNotification({
          content: "",
          url: "",
        });
      })
      .catch((e) => {
        loadingState.value = { isLoading: false, error: e.message };
        alert(e.message);
      });
  }

  function blockUser(user: User) {
    loadingState.value = { isLoading: true, error: null };
    const request = new HttpRequest();
    request.method = "PUT";
    request.url = `/admin/v2/users/${user._id}/block`;
    HttpService.send(request)
      .then(({ data }) => {
        loadingState.value = { isLoading: false, error: null };
        // update the user in the list with the new data from the server response (canComment) and refresh the list
        usersRef.current.users = usersRef.current.users.map((u: User) => {
          if (u._id === user._id) {
            return data.user;
          }
          return u;
        });
        refreshState.reverseValue();
      })
      .catch((e) => {
        loadingState.value = { isLoading: false, error: e.message };
        alert(e.message);
      })
      .finally(() => {
        loadingState.value = { isLoading: false, error: null };
      });
  }
  return (
    <Row className={"flex-column justify-content-center"}>
      <IfCondition condition={loadingState.value.isLoading}>
        <Col className={"text-center"}>
          <AppLoadingSpinner />
        </Col>
      </IfCondition>

      <IfCondition condition={loadingState.value.error != null}>
        <Alert variant={"danger"}>{loadingState.value.error}</Alert>
      </IfCondition>

      <Col>
        <div className="d-flex justo mb-4 align-items-center">
          <Form.Control
            placeholder={strings.search}
            className={"my-2 w-25"}
            value={searchFiledState.value}
            onChange={filterUsers}
          />
        </div>
        <div className={"card-box"}>
          <Table striped bordered responsive size={"md"} className="users-table">
            <thead>
              <tr>
                <th>{strings.users.name}</th>
                <th>{strings.users.email}</th>
                <th>{strings.users.phoneNumber}</th>
                {AppUtil.isUserHaveAccess("/home/users/addEdit") && <th>{strings.users.editSubscription}</th>}
                <th>{strings.users.registeredAt}</th>
              </tr>
            </thead>
            <tbody>
              <ForLoop
                items={usersRef.current.users}
                forEachItem={(user: User, index: number) => {
                  const userImage = user.image
                    ? user.image.location
                    : "https://wp.audiohatdar.com/wp-content/uploads/2021/06/person-placeholder.jpg";
                  return (
                    <tr key={user._id} className={"p-0"}>
                      <td className="userName">
                        <Row className={"p-2"}>
                          <img
                            className="img-responsive rounded-circle"
                            style={{
                              width: "40px",
                              height: "40px",
                            }}
                            src={userImage}
                            alt={user.name}
                            onClick={() => {
                              DialogUtil.showImageDialog(userImage);
                            }}
                          />
                          <h4 className={"mx-2"}>
                            {user.name}
                            {user.isAdmin && <div className="text-muted small">{strings.users.admin}</div>}
                            {AppUtil.isUserHaveAccess("/home/users/addEdit") && (
                              <div className="btn-toolbar mt-2">
                                <div className="btn-group btn-group-sm">
                                  <AppButton
                                    icon="account-remove"
                                    iconFontSize="1rem"
                                    text={strings.users.deleteUserAccount}
                                    className={"btn btn-danger"}
                                    type={"button"}
                                    onClick={() => {
                                      deleteTheUserAccount(user);
                                    }}
                                    tooltipTitle={strings.users.deleteUserAccountTooltip}
                                    tooltipPlacement="top"
                                  />
                                  <AppButton
                                    icon="wordpress"
                                    iconFontSize="1rem"
                                    text={strings.users.showUserInfoFromWordPress}
                                    className={"btn btn-info"}
                                    type={"button"}
                                    onClick={() => {
                                      loadUserInfoFromWP(user);
                                    }}
                                    tooltipTitle={strings.users.showUserInfoFromWordPressTooltip}
                                    tooltipPlacement="top"
                                  />
                                  <AppButton
                                    icon="textbox-password"
                                    iconFontSize="1rem"
                                    text={strings.users.changePassword}
                                    className={"btn btn-danger"}
                                    type={"button"}
                                    onClick={() => {
                                      if (window.confirm(`${strings.users.changePasswordConfirm} ${user.name}!`)) {
                                        const newPassword = prompt(strings.users.pleseEnterTheNewPassword, "");
                                        if (newPassword) {
                                          changeUserPassword(user, newPassword);
                                        }
                                      }
                                    }}
                                    tooltipTitle={strings.users.changePasswordTooltip}
                                    tooltipPlacement="top"
                                  />
                                  <AppButton
                                    icon="message-plus"
                                    iconFontSize="1rem"
                                    text={strings.users.sendNotification}
                                    className={"btn btn-success"}
                                    type={"button"}
                                    onClick={() => {
                                      setShowSendNotification(true);
                                      setSelectedUser(user);
                                    }}
                                    tooltipTitle={strings.users.sendNotificationTooltip}
                                    tooltipPlacement="top"
                                  />
                                </div>
                              </div>
                            )}
                          </h4>
                        </Row>
                      </td>
                      <td className="userEmail" style={{ width: "250px" }}>
                        {user.email}
                        <div className="checkbox checkbox-success checkbox-circle">
                          <input
                            id={`block-user-${index}`}
                            type="checkbox"
                            checked={!user.canComment && user.canComment !== undefined}
                            onChange={(e) => {
                              blockUser(user);
                            }}
                          />
                          <label htmlFor={`block-user-${index}`}>حظر من التعليق</label>
                        </div>
                      </td>
                      <td className="phoneNumber">{user.phoneNumber ? user.phoneNumber : "-"}</td>
                      {AppUtil.isUserHaveAccess("/home/users/addEdit") && (
                        <td className="userEdit">
                          <div className="inner">
                            <EditSubscription
                              className="edit-subscription"
                              user={user}
                              userIndex={index}
                              onSubscriptionChanged={(user, index) => {
                                usersRef.current.users[index] = user;
                                refreshState.reverseValue();
                              }}
                            />
                            <div className="more-buttons-and-info">
                              <AppButton
                                disabled={!user.paidUser}
                                icon="apple"
                                text={strings.books.cut}
                                className={"btn btn-dark btn-sm rounded"}
                                type={"button"}
                                onClick={() => {
                                  sendToRevenuecat(user);
                                }}
                                tooltipTitle={strings.users.sendToRevenuecat}
                                tooltipPlacement="top"
                              />
                              <AppButton
                                icon="wordpress"
                                className="btn btn-dark btn-sm rounded"
                                type={"button"}
                                onClick={() => {
                                  syncUserInfoFromWP(user);
                                }}
                                tooltipTitle="جلب بيانات المستخدم من ووردبريس"
                                tooltipPlacement="top"
                              />
                              <AppButton
                                disabled={true}
                                icon={`${user.paidUser ? "check-circle" : "close-circle"}`}
                                className={`btn btn-${user.paidUser ? "success" : "danger"} btn-sm rounded`}
                                type={"button"}
                                tooltipTitle={user.paidUser ? strings.users.subscribed : strings.users.notSubscribed}
                                tooltipPlacement="top"
                              />
                              <AppButton
                                disabled={true}
                                icon={`${user.autoRenewal?.active ? "check-circle" : "close-circle"}`}
                                className={`btn btn-${user.autoRenewal?.active ? "success" : "danger"} btn-sm rounded`}
                                type={"button"}
                                tooltipTitle={`${strings.users.autoRenewal}: ${
                                  user.autoRenewal?.active ? strings.users.active : strings.users.notActive
                                }`}
                                tooltipPlacement="top"
                              />
                            </div>
                          </div>
                        </td>
                      )}
                      <td style={{ direction: "ltr" }} className="userCreatedAt">
                        {DateTimeUtil.format(user.createdAt)}
                      </td>
                    </tr>
                  );
                }}
              />
            </tbody>
          </Table>
        </div>
      </Col>

      <Col>
        <Pagination
          current={usersRef.current.currentPage}
          total={usersRef.current.totalPages}
          onPageChange={loadMoreUsers}
        />
      </Col>
      <WpInfoModal
        show={showWpInfo}
        handleClose={() => {
          setSelectedUser(undefined);
          setShowWpInfo(false);
        }}
        user={selectedUser}
      />
      {/* <!-- send notification modal --> */}
      <SendNotificationModal
        show={showSendNotification}
        handleClose={() => {
          setSelectedUser(undefined);
          setShowSendNotification(false);
        }}
        user={selectedUser}
        notification={notification}
        handleInputChange={(e) => {
          const noti = { ...notification };
          // @ts-ignore
          noti[e.target.name] = e.target.value;
          setNotification(noti);
        }}
        handleSendNotification={() => {
          sendNotification();
        }}
      />
    </Row>
  );
}
