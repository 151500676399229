//import React from "react";
//import * as io from "socket.io-client";

import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import MustBeLoginAuth from "./guard/MustBeLoginGuard";
import MustBeGuestGuard from "./guard/MustBeGuestGuard";
import LoginScreen from "./screens/auth/login/LoginScreen";
import HomeScreen from "./screens/home/HomeScreen";
//import AppConfigManager from "./data/config/AppConfigManager";

import "./App.css";
//const appConfig = AppConfigManager.getInstance().getAppConfig();
//const socket = io.connect(appConfig.baseUrl);

function App() {
    return (
        <BrowserRouter>
            <Switch>
                <MustBeGuestGuard path={"/auth/login"} component={LoginScreen} />
                <MustBeLoginAuth path={"/home"} component={HomeScreen} />
                <Redirect to={"/home"} />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
