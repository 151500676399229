import { useEffect, useRef } from "react";
import { appStore } from "../../data/store/AppStore";
import { strings } from "../../translation/locale";
import useComponentState from "../../hooks/useComponentState";
import { Alert, Col, FormControl } from "react-bootstrap";
import { IfCondition } from "react-ifloop-ts";
import AppLoadingSpinner from "../../components/AppLoadingSpinner";
import HttpRequest from "../../data/network/HttpRequest";
import HttpService from "../../data/network/HttpService";
import { Book, BooksResponse } from "../../data/model/BooksResponse";
import ToastManager from "../../util/ToastManager";
import { CategoriesResponse, Category } from "../../data/model/CategoriesResponse";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

export default function BooksReorderScreen(props: any) {
    const loadingState = useComponentState({ isLoading: false, error: null });
    const booksState = useComponentState([]);
    const categoriesState = useComponentState([]);
    const allBooksRef = useRef<Book[]>();
    const selectedCatID = useRef<string>("");

    function loadCategories() {
        loadingState.value = { isLoading: true, error: null };
        const request = new HttpRequest();
        request.method = "GET";
        request.url = "/admin/v2/categories";
        HttpService.send(request)
            .then((res) => {
                loadingState.value = { isLoading: false, error: null };
                categoriesState.value = (res.data as CategoriesResponse).categories;
            })
            .catch((e) => {
                loadingState.value = { isLoading: false, error: e.message };
                ToastManager.showError(e.message);
            });
    }

    function loadBooks(categoryId: string) {
        const request = new HttpRequest();
        request.method = "GET";
        request.url = "admin/v2/books/reorder/?categoryId=" + categoryId;
        HttpService.send(request)
            .then((res) => {
                loadingState.value = { isLoading: false, error: null };
                const books = (res.data as BooksResponse).books;
                allBooksRef.current = books;
                booksState.value = books;
            })
            .catch((e) => {
                loadingState.value = { isLoading: false, error: e.message };
            });
    }

    function updateOnServer(result: DropResult) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }

        const items = Array.from(booksState.value) as Book[];
        const [removed] = items.splice(source.index, 1);
        items.splice(destination.index, 0, removed);

        const itemsToSend = items.map((item, index) => {
            return {
                order: index,
                id: item._id,
            };
        });

        const request = new HttpRequest();
        request.method = "PUT";
        request.url = `admin/v2/books/categoryReorder`;
        // set body with category id and itemsToSend
        request.body = {
            categoryId: selectedCatID.current,
            items: itemsToSend,
        };

        HttpService.send(request)
            .then((res) => {
                if (res.data.statusCode === "SUCCESS") {
                    booksState.value = items;
                    loadingState.value = { isLoading: false, error: null };
                } else {
                    loadingState.value = { isLoading: false, error: "unknown error" };
                }
            })
            .catch((e) => {
                loadingState.value = { isLoading: false, error: e.message };
            });
    }
    useEffect(() => {
        appStore.update((state) => {
            state.screenHeaderName = strings.dashboard.booksReorder;
            state.showBackButton = false;
        });
        loadCategories();
        //loadBooks();
    }, []);

    return (
        <>
            <div className="container-fluid">
                <IfCondition condition={loadingState.value.isLoading}>
                    <Col className={"text-center"}>
                        <AppLoadingSpinner />
                    </Col>
                </IfCondition>
                <IfCondition condition={loadingState.value.error != null}>
                    <Alert variant={"danger"}>{loadingState.value.error}</Alert>
                </IfCondition>
            </div>

            <div className="container w-50">
                <FormControl
                    as={"select"}
                    className="w-50 mb-3"
                    value={selectedCatID.current}
                    onChange={(e) => {
                        const categoryId = e.target.value;
                        selectedCatID.current = categoryId;
                        if (categoryId !== "") {
                            loadBooks(categoryId);
                        }
                    }}
                >
                    <option value={""}>{strings.books.plaeseChooseTheCategory}</option>
                    {categoriesState.value.map((category: Category) => {
                        return (
                            <option key={category._id} value={category._id}>
                                {category.label}
                            </option>
                        );
                    })}
                </FormControl>
                <DragDropContext
                    onDragEnd={(result: DropResult) => {
                        updateOnServer(result);
                    }}
                >
                    <Droppable droppableId="characters" ignoreContainerClipping={true} isCombineEnabled={true}>
                        {(provided) => (
                            <ul className="characters" ref={provided.innerRef} {...provided.droppableProps}>
                                {booksState.value.map((book: Book, index: any) => {
                                    return (
                                        <Draggable key={book._id} draggableId={book._id} index={index}>
                                            {(provided) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={"card mb-2"}>
                                                    <div className={"card-body row"}>
                                                        <Col md={2}>
                                                            <img
                                                                className="img-responsive rounded-circle"
                                                                style={{ width: "100px", height: "100px" }}
                                                                src={book.thumbnail?.location}
                                                                alt={book.title}
                                                            />
                                                        </Col>
                                                        <Col className="pr-5">
                                                            {book.title}
                                                            <br />
                                                            {book.subtitle}
                                                            <br />
                                                            <small>{book.author.name}</small>
                                                        </Col>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </>
    );
}
