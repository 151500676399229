import { useEffect, useMemo, useRef } from "react";
import { appStore } from "../../../data/store/AppStore";
import { strings } from "../../../translation/locale";
import useComponentState from "../../../hooks/useComponentState";
import { Link, useHistory } from "react-router-dom";
import { Alert, Badge, Col, Form, Row, FormCheck } from "react-bootstrap";
import DialogUtil from "../../../util/DialogUtil";
import AppButton from "../../../components/AppButton";
import { ForLoop, IfCondition, IfElseCondition } from "react-ifloop-ts";
import DateTimeUtil from "../../../util/DateTimeUtil";
import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import DataTable from "react-data-table-component";
import WarningAlertDialog from "../../../components/WarningAlertDialog";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import { Book, BooksResponse } from "../../../data/model/BooksResponse";
import ToastManager from "../../../util/ToastManager";
import ArrayUtil from "../../../util/ArrayUtil";
import useQueryParams from "../../../hooks/useQueryParams";
import AppUtil from "../../../util/AppUtil";

export default function BooksScreen(props: any) {
	const queryParams = useQueryParams();
	const loadingState = useComponentState({ isLoading: true, error: null });
	const booksState = useComponentState([]);
	const allBooksRef = useRef<Book[]>();
	const searchValueRef = useRef<string>("");
	const deleteActionState = useComponentState({ showDialog: false, book: null });
	const router = useHistory();

	// format duration as hh:mm:ss
	function formatDuration(duration: number) {
		const hours = Math.floor(duration / 3600);
		const minutes = Math.floor((duration - hours * 3600) / 60);
		const seconds = Math.floor(duration - hours * 3600 - minutes * 60);
		return `${hours}:${minutes}:${seconds}`;
	}

	const handleIsRecommended = async (book: any, e: any) => {
		loadingState.value = { isLoading: true, error: null };

		const books = [...booksState.value];
		const bookToUpdate = books.find((b: Book) => b._id === book._id);
		if (bookToUpdate) {
			const request = new HttpRequest();
			request.method = "PUT";
			request.url = "admin/v2/books/" + book._id + "/recommended";
			request.body = {
				isRecommended: e.target.checked,
			};
			bookToUpdate.isRecommended = e.target.checked;

			HttpService.send(request)
				.then((res) => {
					booksState.value = books;
				})
				.catch((e) => {
					window.alert(e.message);
				})
				.finally(() => {
					loadingState.value = { isLoading: false, error: null };
				});
		} else {
			console.log("book not found");
		}
	};
	const columns = useMemo(() => {
		return [
			{
				name: <i className="mdi mdi-star" />,
				selector: (row: any) => {
					return (
						<div className="text-center w-100">
							<FormCheck checked={row.isRecommended ? true : false} onChange={handleIsRecommended.bind("", row)} />
						</div>
					);
				},
				sortable: true,
				width: "5%",
				sortFunction: (a: any, b: any) => {
					return a.isRecommended - b.isRecommended;
				},
			},
			{
				name: strings.books.title,
				selector: (row: any) => {
					return (
						<div className={"pb-1"}>
							<Badge className={"bg-dark py-1 mt-2 white-space-normal"} as="small" style={{ direction: "ltr" }}>
								{row.audioFile && (
									<>
										{formatDuration(row.audioFile.duration)}
										<i className="mdi mdi-clock ml-2" />
									</>
								)}
								{!row.audioFile && "NO AUDIO FILE"}
							</Badge>
							<br />
							<Link to={"/home/books/addEdit?id=" + row._id}>{row.title}</Link>
							<br />
							<small>{row.subtitle}</small>
						</div>
					);
				},
				sortable: true,
				sortFunction: (a: Book, b: Book) => {
					return a.title.localeCompare(b.title);
				},
			},
			{
				name: strings.books.photo,
				width: "130px",
				cell: (row: any) => {
					return (
						<Row
							className={"p-2"}
							onClick={() => {
								DialogUtil.showImageDialogWithTitle(row.thumbnail?.location, row.title);
							}}
						>
							<img
								className="img-responsive rounded-circle"
								style={{ width: "50px", height: "50px" }}
								src={row.thumbnail?.location}
								alt={row.name}
							/>
						</Row>
					);
				},
			},
			{
				name: strings.books.category,
				selector: (row: any) => {
					return (
						<Row className={"justify-content-center wrap"}>
							<ForLoop
								items={row.categories}
								forEachItem={(cat: any) => {
									return (
										<Badge key={cat._id} className={"badge-purple m-1 white-space-normal"}>
											{cat.label}
										</Badge>
									);
								}}
							/>
						</Row>
					);
				},
				sortable: true,
				sortFunction: (a: Book, b: Book) => {
					return a.categories.length - b.categories.length;
				},
			},
			{
				name: strings.books.series,
				selector: (row: any) => {
					return <Badge className={"badge-success m-1 white-space-normal"}>{row.serie?.title || ""}</Badge>;
				},
				sortable: true,
				sortFunction: (a: any, b: any) => {
					return a.serie?.title.localeCompare(b.serie?.title);
				},
			},
			{
				name: strings.books.isFree,
				maxWidth: "130px",
				selector: (row: any) => {
					return (
						<IfElseCondition condition={row.isFree}>
							<i className="mdi mdi-check-circle text-success" style={{ fontSize: "25px" }} />
							<i className="mdi mdi-close-circle text-danger" style={{ fontSize: "25px" }} />
						</IfElseCondition>
					);
				},
				sortable: true,
				sortFunction: (a: Book, b: Book) => {
					return a.isFree ? -1 : 1;
				},
			},
			{
				name: strings.books.isCutBook,
				maxWidth: "130px",
				selector: (row: any) => {
					return (
						<IfElseCondition condition={row.freeAudioFile != null}>
							<i className="mdi mdi-check-circle text-success" style={{ fontSize: "25px" }} />
							<i className="mdi mdi-close-circle text-danger" style={{ fontSize: "25px" }} />
						</IfElseCondition>
					);
				},
				sortable: true,
				sortFunction: (a: Book, b: Book) => {
					return a.freeAudioFile ? -1 : 1;
				},
			},
			{
				name: strings.books.author,
				selector: (row: any) => {
					return (
						<Row
							className={"p-2"}
							onClick={() => {
								DialogUtil.showImageDialog(row.author.image);
							}}
						>
							<img
								className="img-responsive rounded-circle mt-2"
								style={{ width: "30px", height: "30px" }}
								src={row.author.image}
								alt={row.author.name}
							/>
							<h4 className={"mx-1"}>{row.author.name}</h4>
						</Row>
					);
				},
				sortable: true,
				sortFunction: (a: Book, b: Book) => {
					return a.author.name.localeCompare(b.author.name);
				},
			},
			{
				name: strings.books.publishDate,
				minWidth: "250px",
				selector: (row: any) => {
					return <p>{DateTimeUtil.format(row.scheduled)}</p>;
				},
				sortable: true,
				sortFunction: (a: Book, b: Book) => {
					// @ts-ignore
					return a.scheduled.localeCompare(b.scheduled);
				},
			},
			{
				name: strings.books.action,
				wrap: false,
				width: "230px",
				cell: (row: any) => {
					return (
						<Row key={row._id}>
							<Col md={3}>
								{row.audioFile && (
									<AppButton
										icon="share-variant"
										text={strings.books.cut}
										className={"btn btn-dark btn-sm rounded"}
										type={"button"}
										onClick={() => {
											const url = `https://audiohatdar.com/app-sharing?bookid=${row._id}`;
											navigator.clipboard.writeText(url).then(
												function () {
													if (window.confirm(`${strings.books.clipboardSuccess}\n${strings.books.showQRCode}`)) {
														window.open(`https://api.qrserver.com/v1/create-qr-code/?size=350x350&data=${url}`);
													}
												},
												function () {
													/* clipboard write failed */
													window.alert("Failed to copy to clipboard");
												}
											);
										}}
										tooltipTitle={strings.books.generateSharingUrl}
										tooltipPlacement="top"
									/>
								)}
							</Col>
							{AppUtil.isUserHaveAccess("/home/books/delete") && (
								<Col md={3}>
									<AppButton
										icon="delete"
										text={strings.delete}
										className={"btn btn-danger btn-sm rounded"}
										type={"button"}
										onClick={() => {
											deleteActionState.value = { book: row, showDialog: true };
										}}
										tooltipTitle={strings.delete}
										tooltipPlacement="top"
									/>
								</Col>
							)}
							<Col md={3}>
								{row.audioFile && (
									<AppButton
										icon="download"
										text={strings.books.download}
										className={"btn btn-info btn-sm rounded"}
										type={"button"}
										onClick={() => {
											window.open(row.audioFile?.location, "_blank");
										}}
										tooltipTitle={strings.books.download}
										tooltipPlacement="top"
									/>
								)}
							</Col>

							{AppUtil.isUserHaveAccess("/home/books/addEdit") && (
								<Col md={3}>
									{row.audioFile && (
										<AppButton
											icon="pencil"
											text={strings.books.cut}
											className={"btn btn-primary btn-sm rounded"}
											type={"button"}
											onClick={() => {
												cutBook(row);
											}}
											tooltipTitle={strings.books.cut}
											tooltipPlacement="top"
										/>
									)}
								</Col>
							)}
						</Row>
					);
				},
			},
		];
	}, []);

	function loadBooks() {
		const request = new HttpRequest();
		request.method = "GET";
		request.url = "admin/v2/books";
		if (queryParams.categoryId) {
			request.url = "admin/v2/books?categoryId=" + queryParams.categoryId;
		} else if (queryParams.authorId) {
			request.url = "admin/v2/books?authorId=" + queryParams.authorId;
		}
		HttpService.send(request)
			.then((res) => {
				loadingState.value = { isLoading: false, error: null };
				const books = (res.data as BooksResponse).books;
				allBooksRef.current = books;
				booksState.value = books;
			})
			.catch((e) => {
				loadingState.value = { isLoading: false, error: e.message };
			});
	}

	function filterBooks(event: any) {
		searchValueRef.current = event.target.value;
		const searchWordTrimmed = searchValueRef.current.trim();
		booksState.value = allBooksRef.current?.filter((book) => {
			return (
				book.title.includes(searchWordTrimmed) ||
				book.subtitle.includes(searchWordTrimmed) ||
				book.author.name.includes(searchWordTrimmed) ||
				book.categories.some((cat) => cat.label.includes(searchWordTrimmed))
			);
		});
	}

	function deleteBook() {
		deleteActionState.value = (old: any) => {
			return { ...old, showDialog: false };
		};

		const request = new HttpRequest();
		request.method = "DELETE";
		request.url = `admin/v2/books/${deleteActionState.value.book._id}`;
		HttpService.send(request)
			.then((res) => {
				ToastManager.showSuccess(res.data.message);
				booksState.value = ArrayUtil.findByIdAndRemove(deleteActionState.value.book, booksState.value);
				ArrayUtil.findByIdAndRemove(deleteActionState.value.book, allBooksRef.current as any[], false);
			})
			.catch((e) => {
				ToastManager.showError(e.message);
			});
	}

	function cutBook(book: Book) {
		const request = new HttpRequest();
		request.method = "PUT";
		request.url = "admin/v2/books/" + book._id + "/cut";
		HttpService.send(request)
			.then((res) => {
				ToastManager.showSuccess(res.data.message);
				book.freeAudioFile = res.data.freeAudioFile;
				booksState.clone();
			})
			.catch((e) => {
				ToastManager.showError(e.message);
			});
	}

	useEffect(() => {
		appStore.update((state) => {
			state.screenHeaderName = strings.dashboard.books;
			state.showBackButton = false;
		});
		loadBooks();
	}, []);

	function startCutAudio() {
		const request = new HttpRequest();
		request.method = "POST";
		request.url = "/admin/v2/audioCutter";
		HttpService.send(request)
			.then((res) => {
				ToastManager.showSuccess(res.data.message);
			})
			.catch((e) => {
				ToastManager.showError(e.message);
			});
	}

	return (
		<Row className={"flex-column justify-content-center"}>
			<IfCondition condition={loadingState.value.isLoading}>
				<Col className={"text-center"}>
					<AppLoadingSpinner />
				</Col>
			</IfCondition>

			<IfCondition condition={loadingState.value.error != null}>
				<Alert variant={"danger"}>{loadingState.value.error}</Alert>
			</IfCondition>

			<IfCondition condition={!loadingState.value.isLoading && loadingState.value.error == null}>
				<Col>
					{AppUtil.isUserHaveAccess("/home/books/addEdit") && (
						<>
							<AppButton
								text={strings.books.addNew}
								onClick={() => {
									router.push("/home/books/addEdit");
								}}
								margin={"m-2 mx-4"}
							/>
							<AppButton
								text={strings.books.cutAudios}
								onClick={() => {
									startCutAudio();
								}}
								margin={"m-2 mx-4"}
								className={"btn btn-danger waves-effect w-md waves-danger"}
							/>
						</>
					)}
				</Col>

				<Col>
					<Form.Control placeholder={strings.search} className={"my-2 w-25"} value={searchValueRef.current} onChange={filterBooks} />
				</Col>

				<Col>
					<DataTable
						pagination={true}
						paginationPerPage={10}
						columns={columns}
						data={booksState.value}
						responsive={true}
						paginationResetDefaultPage={true}
						sortIcon={<i className="mdi mdi-sort" />}
					/>
				</Col>

				<WarningAlertDialog
					show={deleteActionState.value.showDialog}
					onCancel={() => {
						deleteActionState.value = (oldState: any) => {
							return { ...oldState, showDialog: false };
						};
					}}
					onConfirm={deleteBook}
					title={strings.alert.sureDelete}
				/>
			</IfCondition>
		</Row>
	);
}
