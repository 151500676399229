import { Link, Redirect, Switch, useHistory, NavLink } from "react-router-dom";
import { appStore } from "../../data/store/AppStore";
import React, { useEffect, useMemo } from "react";
import UserCache from "../../data/cache/UserCache";
import { strings } from "../../translation/locale";
import MustBeLoginAuth from "../../guard/MustBeLoginGuard";
import SettingsScreen from "../settings/SettingsScreen";
//import BackupScreen from "../backup/BackupScreen";
import TeamMembersScreen from "../team-members/list/TeamMembersScreen";
import AddEditTeamMemberScreen from "../team-members/add-edit-team-member/AddEditTeamMemberScreen";
import AuthorsScreen from "../authors/list/AuthorsScreen";
import AddEditAuthorScreen from "../authors/add-edit/AddEditAuthorScreen";
import AppCache from "../../data/cache/AppCache";
import PagesScreen from "../pages/list/PagesScreen";
import AddEditPageScreen from "../pages/add-edit/AddEditPageScreen";
import CategoriesScreen from "../categories/list/CategoriesScreen";
import $ from "jquery";
import homeItems from "./homeItems";

import AddEditCategoryScreen from "../categories/add-edit/AddEditCategoryScreen";
import UsersScreen from "../users/UsersScreen";
// admins
import AdminsScreen from "../admins/AdminsScreen";

import AppButton from "../../components/AppButton";
import DashboardScreen from "../dashboard/DashboardScreen";
import BooksScreen from "../books/list/BooksScreen";
import BooksReorderScreen from "../books-reorder/BooksReorderScreen";
import BookCommentsScreen from "../book-comments/BookCommentsScreen";
import AddEditBookScreen from "../books/add-edit/AddEditBookScreen";
import AnnouncmentsScreen from "../announcements/list/AnnouncementsScreen";
import AddEditAnnouncementScreen from "../announcements/add-edit/AddEditAnnouncementScreen";
import FilesScreen from "../files/index";

import { IfCondition } from "react-ifloop-ts";
import TimeSpentScreen from "../time-spent/list/TimeSpentScreen";

// series
import SeriesScreen from "../serieses/list/SeriesesScreen";
import AddEditSeriesScreen from "../serieses/add-edit/AddEditSeriesScreen";
import AppUtil from "../../util/AppUtil";
//import * as io from "socket.io-client";
//import AppConfigManager from "../../data/config/AppConfigManager";

//const appConfig = AppConfigManager.getInstance().getAppConfig();
//const socket = io.connect(appConfig.baseUrl);

export default function HomeScreen() {
  const appState = appStore.useState((s) => s);
  const admin = useMemo(() => {
    return UserCache.getUser();
  }, []);
  const router = useHistory();
  useEffect(() => {
    $("button.button-menu-mobile").on("click", () => {
      // hide or show right menu.
      const menu = $("div.side-menu");
      const pageContent = $("div.content-page");
      const isHidden = $(menu).hasClass("d-none");
      const topBar = $("ul.nav.navbar-nav");
      if (isHidden) {
        // show it
        $(menu).removeClass("d-none");
        $(pageContent).css({ "margin-right": "250px" });
        $(topBar).css("height", "");
      } else {
        // hide it.
        $(menu).addClass("d-none");
        $(pageContent).css({ "margin-right": "0" });
        $(topBar).css({ height: "60px" });
      }
    });
  }, []);

  return (
    <div id="wrapper">
      <div className="topbar">
        <div className="topbar-left">
          <Link to="/" className="logo">
            <span>
              Audio<span>hat</span>
            </span>
            <i className="mdi mdi-layers" />
          </Link>
        </div>

        <div className="navbar navbar-default" role="navigation">
          <div className="container-fluid">
            <ul className="nav navbar-nav list-inline navbar-left">
              <li className="list-inline-item">
                <button className="button-menu-mobile open-left">
                  <i className="mdi mdi-menu" />
                </button>
              </li>
              <li className="list-inline-item">
                <h4 className="page-title d-inline-block">{appState.screenHeaderName}</h4>
                <IfCondition condition={appState.showBackButton}>
                  <button
                    className="btn btn-icon waves-effect waves-light btn-purple mx-4 p-0 px-2"
                    onClick={() => {
                      router.goBack();
                    }}>
                    <i className="mdi mdi-arrow-left" style={{ fontSize: "24px" }} />
                  </button>
                </IfCondition>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="left side-menu" style={{ overflowY: "scroll" }}>
        <div className="sidebar-inner slimscrollleft">
          <div className="user-box">
            <div className="user-img mb-2">
              <img
                src={
                  admin?.image
                    ? admin.image.location
                    : "https://wp.audiohatdar.com/wp-content/uploads/2021/06/person-placeholder.jpg"
                }
                alt="user-img"
                title={admin?.name}
                className="rounded-circle img-thumb"
              />
            </div>
            <h4>
              <span>{admin!.name}</span>
            </h4>
            <AppButton
              text={strings.logout}
              className={"btn btn-danger btn-trans waves-effect w-md waves-danger"}
              onClick={() => {
                AppCache.getInstance().clear();
                router.push("/auth/login");
              }}
            />
          </div>
          <div id="sidebar-menu">
            <ul>
              {homeItems.map((item) => {
                if (AppUtil.isUserHaveAccess(item.path)) {
                  return (
                    <li key={item.key}>
                      <NavLink activeClassName={"active"} to={`${item.path}`} className="waves-effect">
                        <i className={`mdi mdi-${item.icon}`} />
                        <span> {item.title} </span>
                      </NavLink>
                    </li>
                  );
                }
                return true;
              })}
            </ul>
            <div className="clearfix" />
          </div>
          <div className="clearfix" />
        </div>
      </div>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <Switch>
              <MustBeLoginAuth path={"/home/dashboard"} component={DashboardScreen} />
              <MustBeLoginAuth path={"/home/settings"} component={SettingsScreen} />
              {/* <MustBeLoginAuth path={"/home/backup"} component={BackupScreen} /> */}
              <MustBeLoginAuth path={"/home/files"} component={FilesScreen} />
              <MustBeLoginAuth path={"/home/teamMembers"} component={TeamMembersScreen} exact={true} />
              <MustBeLoginAuth path={"/home/teamMembers/addEdit"} component={AddEditTeamMemberScreen} />
              <MustBeLoginAuth path={"/home/authors"} component={AuthorsScreen} exact={true} />
              <MustBeLoginAuth path={"/home/authors/addEdit"} component={AddEditAuthorScreen} />
              <MustBeLoginAuth path={"/home/pages"} component={PagesScreen} exact={true} />
              <MustBeLoginAuth path={"/home/pages/addEdit"} component={AddEditPageScreen} />
              <MustBeLoginAuth path={"/home/categories"} component={CategoriesScreen} exact={true} />
              <MustBeLoginAuth path={"/home/categories/addEdit"} component={AddEditCategoryScreen} />
              <MustBeLoginAuth path={"/home/users"} component={UsersScreen} />

              <MustBeLoginAuth path={"/home/admins"} component={AdminsScreen} exact={true} />

              <MustBeLoginAuth path={"/home/books"} component={BooksScreen} exact={true} />
              <MustBeLoginAuth path={"/home/books/addEdit"} component={AddEditBookScreen} />
              <MustBeLoginAuth path={"/home/book-commetns"} component={BookCommentsScreen} exact={true} />

              <MustBeLoginAuth path={"/home/book-reorder"} component={BooksReorderScreen} exact={true} />

              <MustBeLoginAuth path={"/home/serieses"} component={SeriesScreen} exact={true} />
              <MustBeLoginAuth path={"/home/serieses/addEdit"} component={AddEditSeriesScreen} />

              <MustBeLoginAuth path={"/home/announcements"} component={AnnouncmentsScreen} exact={true} />
              <MustBeLoginAuth path={"/home/announcements/addEdit"} component={AddEditAnnouncementScreen} />

              <MustBeLoginAuth path={"/home/timeSpent"} component={TimeSpentScreen} />
              <Redirect to={"/home/books"} />
            </Switch>
          </div>
        </div>
        <footer className="footer text-right">{strings.dashboard.copyrights}</footer>
      </div>
    </div>
  );
}
