import { strings } from "../translation/locale";

interface Props {
    type?: string;
    margin?: string;
}

export default function AppLoadingSpinner(props: Props) {
    function getClasses() {
        let className = "spinner-border";
        if (props.type) {
            className += " " + props.type;
        } else {
            className += " text-dark";
        }
        if (props.margin) {
            className += " " + props.margin;
        } else {
            className += " m-2";
        }
        return className;
    }

    return (
        <div className="app-blocking">
            <div className={getClasses()} style={{ width: "5rem", height: "5rem" }}>
                <span className="sr-only">{strings.alert.pleaseWait}</span>
            </div>
            <span className="badge bg-dark text-light px-2 py-2 fs-3 fw-bolder">{strings.alert.pleaseWait}</span>
        </div>
    );
}
