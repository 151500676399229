import SweetAlert from "react-bootstrap-sweetalert";
import { Col, Form, Row, Table } from "react-bootstrap";
import { useRef } from "react";
import { ForLoop, IfCondition } from "react-ifloop-ts";
import AppLoadingSpinner from "../../components/AppLoadingSpinner";

import { User, UsersResponse } from "../../data/model/UsersResponse";
import { strings } from "../../translation/locale";
import useComponentState from "../../hooks/useComponentState";
import HttpRequest from "../../data/network/HttpRequest";
import HttpService from "../../data/network/HttpService";
import AppButton from "../../components/AppButton";
import ToastManager from "../../util/ToastManager";

interface Props {
  title: string;
  onCancel: () => void;
  show: boolean;
}

export default function NewAdminDialog(props: Props) {
  const searchFiledState = useComponentState("");
  const loadingState = useComponentState({ isLoading: false, error: null });
  const usersRef = useRef<any>({ users: [] });

  async function loadUsers(searchQuery: string) {
    loadingState.value = { isLoading: true };
    const request = new HttpRequest();
    request.method = "GET";
    request.url = `/admin/v2/users/not-admins?q=${searchQuery}`;

    HttpService.send(request)
      .then((res) => {
        const usersResults = (res.data as UsersResponse).usersResults;
        if (usersResults.users.length > 0) {
          usersRef.current.users = usersResults.users;
        }
        loadingState.value = { isLoading: false, error: null };
      })
      .catch((e) => {
        loadingState.value = { isLoading: false, error: e.message };
      });
  }
  function onConfirmAddAdmin(userId: string) {
    //2 call delete member api.
    const request = new HttpRequest();
    request.url = `/admin/v2/users/admins/${userId}/add`;
    request.method = "PUT";
    HttpService.send(request)
      .then((res) => {
        loadUsers(searchFiledState.value);
      })
      .catch((e) => {
        ToastManager.showError(e.message);
      });
  }
  return (
    <SweetAlert
      title={props.title}
      customClass="w-75"
      onConfirm={() => {
        console.log("confirmd");
      }}
      onCancel={props.onCancel}
      show={props.show}
      confirmBtnText={strings.admins.add}
      cancelBtnText={strings.close}
      showCancel={true}
      showConfirm={false}
      openAnim={true}
      closeAnim={true}
      cancelBtnBsStyle="dark">
      <div>
        <IfCondition condition={loadingState.value.isLoading}>
          <Col className={"text-center"}>
            <AppLoadingSpinner />
          </Col>
        </IfCondition>
        <Form.Control
          placeholder={strings.search}
          value={searchFiledState.value}
          onChange={async (event) => {
            const searchQuery = await event.target.value;
            searchFiledState.value = searchQuery;
            loadUsers(searchQuery);
          }}
        />
        {usersRef.current.users.length > 0 && (
          <div className={"card-box"}>
            <Table striped bordered responsive size={"md"}>
              <thead>
                <tr>
                  <th>{strings.users.name}</th>
                  <th>{strings.users.email}</th>
                  <th>{strings.teamMembers.action}</th>
                </tr>
              </thead>
              <tbody>
                <ForLoop
                  items={usersRef.current.users}
                  forEachItem={(user: User, index: number) => {
                    const userImage = user.image
                      ? user.image.location
                      : "https://wp.audiohatdar.com/wp-content/uploads/2021/06/person-placeholder.jpg";
                    return (
                      <tr key={user._id} className={"p-0"}>
                        <td>
                          <Row className={"p-2"}>
                            <img
                              className="img-responsive rounded-circle"
                              style={{ width: "40px", height: "40px" }}
                              src={userImage}
                              alt={user.name}
                            />
                            <h4 className={"mx-2"}>
                              {user.name}
                              {user.isAdmin && <div className="text-muted small">{strings.users.admin}</div>}
                            </h4>
                          </Row>
                        </td>
                        <td className="align-middle" style={{ width: "250px" }}>
                          {user.email}
                        </td>
                        <td className="align-middle">
                          <AppButton
                            text={strings.admins.addAsAdmin}
                            className={"btn btn-primary btn-trans waves-effect waves-primary mx-2 btn-sm"}
                            type={"button"}
                            onClick={() => {
                              onConfirmAddAdmin(user._id);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  }}
                />
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </SweetAlert>
  );
}
