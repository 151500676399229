import { useEffect } from "react";
import { IfCondition } from "react-ifloop-ts";
import useComponentState from "../hooks/useComponentState";

interface Props {
    file: any;
    label?: string;
    url?: string;
}

export default function AppCircleFileImage(props: Props) {
    const imageState = useComponentState(null);
    useEffect(() => {
        if (props.file) {
            const reader = new FileReader();
            reader.onloadend = function (e) {
                imageState.value = reader.result;
                //reader.readAsDataURL(props.file);
            };
            reader.readAsDataURL(props.file);
        } else {
            imageState.value = props.url;
        }
    }, [props]);

    return (
        <div>
            <IfCondition condition={imageState.value != null}>
                <img className="img-responsive rounded-circle" style={{ width: "80px", height: "80px" }} src={imageState.value} alt={""} />
            </IfCondition>
        </div>
    );
}
