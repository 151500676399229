import { User } from "../../data/model/UsersResponse";
import useComponentState from "../../hooks/useComponentState";
import { IfCondition, IfElseCondition } from "react-ifloop-ts";
import AppButton from "../../components/AppButton";
import { strings } from "../../translation/locale";
import { Col, Form, Row } from "react-bootstrap";
import AppLoadingSpinner from "../../components/AppLoadingSpinner";
import HttpRequest from "../../data/network/HttpRequest";
import HttpService from "../../data/network/HttpService";
import ToastManager from "../../util/ToastManager";
import DateTimeUtil from "../../util/DateTimeUtil";

interface Props {
    user: User;
    userIndex: number;
    onSubscriptionChanged: (user: User, userIndex: number) => void;
    className?: string;
}

export default function EditSubscription(props: Props) {
    const editState = useComponentState(false);
    const subscribeUntilState = useComponentState(DateTimeUtil.format(props.user.activeUntil, "YYYY-MM-DD"));
    const loadingState = useComponentState(false);

    const editButton = props.user.paidUser ? DateTimeUtil.format(props.user.activeUntil, "YYYY-MM-DD") : strings.edit;
    function updateUserSubscription() {
        loadingState.reverseValue();
        // update subscription
        const request = new HttpRequest();
        request.method = "PUT";
        request.url = `/admin/v2/users/${props.user._id}/subscription`;
        request.body = { activeUntil: subscribeUntilState.value };
        HttpService.send(request)
            .then((res) => {
                loadingState.reverseValue();
                editState.value = false;
                ToastManager.showSuccess(res.data.message);
                props.onSubscriptionChanged(res.data.user, props.userIndex);
            })
            .catch((e) => {
                loadingState.reverseValue();
                ToastManager.showError(e.message);
            });
    }

    return (
        <div className={props.className}>
            <IfElseCondition condition={loadingState.value}>
                <div className={"text-center"}>
                    <AppLoadingSpinner />
                </div>
                <div>
                    <IfCondition condition={!editState.value}>
                        <AppButton
                            text={editButton}
                            className={"btn btn-info btn-trans waves-effect w-md waves-info"}
                            onClick={() => {
                                editState.value = true;
                            }}
                        />
                    </IfCondition>
                    <IfCondition condition={editState.value}>
                        <Row>
                            <Col lg={10}>
                                {" "}
                                <Form.Control
                                    type={"date"}
                                    name={"subscribeUntil"}
                                    placeholder={strings.users.subscribeUntil}
                                    value={subscribeUntilState.value}
                                    onChange={(event) => {
                                        subscribeUntilState.value = event.target.value;
                                    }}
                                />
                            </Col>
                            <div className={"m-sm-2 m-lg-0"}>
                                <button
                                    className="btn btn-icon waves-effect waves-light btn-success"
                                    onClick={() => {
                                        updateUserSubscription();
                                    }}>
                                    <i className="mdi mdi-check" />
                                </button>
                            </div>
                        </Row>
                    </IfCondition>
                </div>
            </IfElseCondition>
        </div>
    );
}
