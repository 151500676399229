import * as prod from '../../config/prod.json';
import * as dev from '../../config/dev.json';
import * as test from '../../config/local.json';
import * as awsprod from '../../config/awsprod.json';
import * as awsdev from '../../config/awsdev.json';
export default class AppConfigManager {

    private static instance: (AppConfigManager | null) = null;
    private readonly appConfig: any;

    constructor() {
        const env = process.env.REACT_APP_ENV;
        if (env === "prod") {
            this.appConfig = prod;
        } else if (env === 'dev') {
            this.appConfig = dev;
        } else if (env === 'awsprod') {
            this.appConfig = awsprod
        } else if (env === 'awsdev') {
            this.appConfig = awsdev;
        }
        else {
            // localhost
            this.appConfig = test;
        }
    }

    public static getInstance(): AppConfigManager {
        if (!AppConfigManager.instance) {
            AppConfigManager.instance = new AppConfigManager();
        }
        return AppConfigManager.instance;
    }

    public getAppConfig() {
        return this.appConfig;
    }
}