import { Alert, Col, Form, Row } from "react-bootstrap";
import AppButton from "../../../components/AppButton";
import { strings } from "../../../translation/locale";
import { IfCondition } from "react-ifloop-ts";
import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import DataTable from "react-data-table-component";
import ExpandRowComponent from "../../team-members/list/ExpandRowComponent";
import WarningAlertDialog from "../../../components/WarningAlertDialog";
import React, { useEffect, useMemo, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import useComponentState from "../../../hooks/useComponentState";
import { appStore } from "../../../data/store/AppStore";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import { Author, AuthorsResponse } from "../../../data/model/AuthorsResponse";
import ArrayUtil from "../../../util/ArrayUtil";
import ToastManager from "../../../util/ToastManager";
import DialogUtil from "../../../util/DialogUtil";
import AppUtil from "../../../util/AppUtil";

export default function AuthorsScreen() {
	const router = useHistory();
	const loadingInfoState = useComponentState({ isLoading: true, error: null });
	const authorsState = useComponentState([]);
	const allAuthorsRef = useRef<Author[]>();
	const searchValueRef = useRef<string>("");
	const deleteActionState = useComponentState({ showDialog: false, author: null });

	const columns = useMemo(() => {
		return [
			{
				name: strings.authors.name,
				cell: (row: any) => {
					return (
						<Row className={"p-2"} style={{ maxWidth: "300px" }}>
							<img
								className="img-responsive rounded-circle"
								style={{ width: "50px", height: "50px" }}
								src={row.avatar?.location}
								alt={row.name}
								onClick={() => {
									DialogUtil.showImageDialog(row.avatar?.location);
								}}
							/>
							<Link to={"/home/books?authorId=" + row._id} className={"mx-2 my-3"}>
								{row.name}
							</Link>
						</Row>
					);
				},
			},
			{
				name: strings.teamMembers.bio,

				cell: (row: any) => {
					return <h5 className={"max-lines"}>{row.bio}</h5>;
				},
			},
			{
				name: strings.teamMembers.action,
				cell: (row: any, index: number) => {
					return (
						<Row key={row._id}>
							<Col>
								{AppUtil.isUserHaveAccess("/home/authors/addEdit") && (
									<AppButton
										text={strings.edit}
										className={"btn btn-primary btn-trans waves-effect waves-primary mx-2 btn-sm"}
										type={"button"}
										onClick={() => {
											router.push({
												pathname: "/home/authors/addEdit",
												search: "?id=" + row._id,
											});
										}}
									/>
								)}
								{AppUtil.isUserHaveAccess("/home/authors/delete") && (
									<AppButton
										text={strings.delete}
										className={"btn btn-danger btn-trans waves-effect waves-danger btn-sm"}
										type={"button"}
										onClick={() => {
											deleteActionState.value = { showDialog: true, author: row };
										}}
									/>
								)}
							</Col>
						</Row>
					);
				},
			},
		];
	}, []);

	function deleteAuthor() {
		// 1 hide delete dialog
		deleteActionState.value = (oldState: any) => {
			return { ...oldState, showDialog: false };
		};
		//2 call delete member api.
		const request = new HttpRequest();
		request.url = `admin/v2/authors/${deleteActionState.value.author._id}`;
		request.method = "DELETE";
		HttpService.send(request)
			.then((res) => {
				authorsState.value = ArrayUtil.findByIdAndRemove(deleteActionState.value.author, authorsState.value);
				allAuthorsRef.current = ArrayUtil.findByIdAndRemove(deleteActionState.value.author, allAuthorsRef.current as any[], false);
				ToastManager.showSuccess(res.data.message);
			})
			.catch((e) => {
				ToastManager.showError(e.message);
			});
	}

	function loadAuthors() {
		const request = new HttpRequest();
		request.method = "GET";
		request.url = "admin/v2/authors";
		HttpService.send(request)
			.then((res) => {
				loadingInfoState.value = { isLoading: false, error: null };
				const authors = (res.data as AuthorsResponse).authors;
				authorsState.value = authors;
				allAuthorsRef.current = authors;
			})
			.catch((e) => {
				loadingInfoState.value = { isLoading: false, error: e.message };
			});
	}

	function filterAuthors(event: any) {
		const searchValue = event.target.value.trim();
		searchValueRef.current = searchValue;
		if (searchValue !== "") {
			// filter by author name.
			authorsState.value = allAuthorsRef.current?.filter((author) => {
				return author.name.includes(searchValue);
			});
		} else {
			authorsState.value = allAuthorsRef.current;
		}
	}

	useEffect(() => {
		appStore.update((old) => {
			old.screenHeaderName = strings.dashboard.authors;
			old.showBackButton = false;
		});
		loadAuthors();
	}, []);

	return (
		<Row className={"flex-column justify-content-center"}>
			{AppUtil.isUserHaveAccess("/home/authors/addEdit") && (
				<Col>
					<AppButton
						text={strings.authors.addNew}
						onClick={() => {
							router.push("/home/authors/addEdit");
						}}
						margin={"m-2"}
					/>
				</Col>
			)}

			<IfCondition condition={loadingInfoState.value.isLoading}>
				<Col className={"text-center"}>
					<AppLoadingSpinner />
				</Col>
			</IfCondition>

			<IfCondition condition={loadingInfoState.value.error != null}>
				<Alert variant={"danger"}>{loadingInfoState.value.error}</Alert>
			</IfCondition>

			<IfCondition condition={!loadingInfoState.value.isLoading && loadingInfoState.value.error == null}>
				<Col>
					<Form.Control placeholder={strings.search} className={"my-2 w-25"} value={searchValueRef.current} onChange={filterAuthors} />
					<DataTable
						pagination={true}
						paginationPerPage={10}
						columns={columns}
						data={authorsState.value}
						expandableRows={true}
						expandableRowsComponent={ExpandRowComponent}
						paginationResetDefaultPage={true}
					/>
				</Col>
			</IfCondition>
			<WarningAlertDialog
				show={deleteActionState.value.showDialog}
				onCancel={() => {
					deleteActionState.value = (oldState: any) => {
						return { ...oldState, showDialog: false };
					};
				}}
				onConfirm={deleteAuthor}
				title={strings.alert.sureDelete}
			/>
		</Row>
	);
}
