import useComponentState from "../../../hooks/useComponentState";
import React, { useEffect, useMemo } from "react";
import { appStore } from "../../../data/store/AppStore";
import { strings } from "../../../translation/locale";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import { PagesResponse } from "../../../data/model/PagesResponse";
import { Col, Row } from "react-bootstrap";
import AppButton from "../../../components/AppButton";
import { IfCondition } from "react-ifloop-ts";
import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import DataTable from "react-data-table-component";
import WarningAlertDialog from "../../../components/WarningAlertDialog";
import { useHistory } from "react-router-dom";
import ExpandPageRowComponent from "./ExpandPageRowComponent";
import ToastManager from "../../../util/ToastManager";
import ArrayUtil from "../../../util/ArrayUtil";
import AppUtil from "../../../util/AppUtil";

export default function PagesScreen() {
	const loadingInfoState = useComponentState({ isLoading: true, error: null });
	const pagesState = useComponentState([]);
	const router = useHistory();
	const deleteActionState = useComponentState({ showDialog: false, page: null });
	const columns = useMemo(() => {
		return [
			{
				name: strings.pages.title,
				selector: (row: any) => row.title,
			},
			{
				name: strings.pages.content,
				cell: (row: any) => {
					return <h5 className={"max-lines"}>{row.content}</h5>;
				},
			},
			{
				name: strings.pages.slug,
				selector: (row: any) => row.slug,
			},
			{
				name: strings.teamMembers.action,
				cell: (row: any, index: number) => {
					return (
						<Row key={row._id}>
							<Col>
								{AppUtil.isUserHaveAccess("/home/pages/addEdit") && (
									<AppButton
										text={strings.edit}
										className={"btn btn-primary btn-trans waves-effect waves-primary mx-2 btn-sm"}
										type={"button"}
										onClick={() => {
											router.push({
												pathname: "/home/pages/addEdit",
												search: "?id=" + row._id,
											});
										}}
									/>
								)}
								{AppUtil.isUserHaveAccess("/home/pages/delete") && (
									<AppButton
										text={strings.delete}
										className={"btn btn-danger btn-trans waves-effect waves-danger btn-sm"}
										type={"button"}
										onClick={() => {
											deleteActionState.value = { showDialog: true, page: row };
										}}
									/>
								)}
							</Col>
						</Row>
					);
				},
			},
		];
	}, []);

	function loadPages() {
		loadingInfoState.value = { isLoading: true, error: null };
		const request = new HttpRequest();
		request.url = "/admin/v2/pages";
		request.method = "GET";
		HttpService.send(request)
			.then((res) => {
				loadingInfoState.value = { isLoading: false, error: null };
				pagesState.value = (res.data as PagesResponse).pages;
			})
			.catch((e) => {
				loadingInfoState.value = { isLoading: false, error: e.message };
			});
	}

	function deletePage() {
		// 1 hide delete dialog
		deleteActionState.value = (oldState: any) => {
			return { ...oldState, showDialog: false };
		};
		// call api.
		const request = new HttpRequest();
		request.method = "DELETE";
		request.url = `/admin/v2/pages/${deleteActionState.value.page._id}`;
		HttpService.send(request)
			.then((res) => {
				pagesState.value = ArrayUtil.findByIdAndRemove(deleteActionState.value.page, pagesState.value);
				ToastManager.showSuccess(res.data.message);
			})
			.catch((e) => {
				ToastManager.showError(e.message);
			});
	}

	useEffect(() => {
		appStore.update((store) => {
			store.screenHeaderName = strings.dashboard.pages;
			store.showBackButton = false;
		});
		loadPages();
	}, []);

	return (
		<Row className={"flex-column justify-content-center"}>
			{AppUtil.isUserHaveAccess("/home/pages/addEdit") && (
				<Col>
					<AppButton
						text={strings.pages.addNew}
						onClick={() => {
							router.push("/home/pages/addEdit");
						}}
						margin={"m-2"}
					/>
				</Col>
			)}
			<IfCondition condition={loadingInfoState.value.isLoading}>
				<Col className={"text-center"}>
					<AppLoadingSpinner />
				</Col>
			</IfCondition>
			<IfCondition condition={!loadingInfoState.value.isLoading && loadingInfoState.value.error == null}>
				<Col>
					<DataTable
						pagination={true}
						paginationPerPage={10}
						columns={columns}
						data={pagesState.value}
						expandableRows={true}
						expandableRowsComponent={ExpandPageRowComponent}
					/>
				</Col>
			</IfCondition>
			<WarningAlertDialog
				show={deleteActionState.value.showDialog}
				onCancel={() => {
					deleteActionState.value = (oldState: any) => {
						return { ...oldState, showDialog: false };
					};
				}}
				onConfirm={deletePage}
				title={strings.alert.sureDelete}
			/>
		</Row>
	);
}
