import { Alert, Col, Form, Row } from "react-bootstrap";
import AppButton from "../../../components/AppButton";
import { strings } from "../../../translation/locale";
import { IfCondition } from "react-ifloop-ts";
import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import DataTable from "react-data-table-component";
import DateTimeUtil from "../../../util/DateTimeUtil";

import WarningAlertDialog from "../../../components/WarningAlertDialog";
import React, { useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import useComponentState from "../../../hooks/useComponentState";
import { appStore } from "../../../data/store/AppStore";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import { Announcment, AnnouncmentResponse } from "../../../data/model/AnnouncmentResponse";
import ArrayUtil from "../../../util/ArrayUtil";
import ToastManager from "../../../util/ToastManager";
import DialogUtil from "../../../util/DialogUtil";
import AppUtil from "../../../util/AppUtil";

export default function AnnouncmentsScreen() {
	const router = useHistory();
	const loadingInfoState = useComponentState({ isLoading: true, error: null });
	const announcementsState = useComponentState([]);
	const allAnnouncmentsRef = useRef<Announcment[]>();
	const searchValueRef = useRef<string>("");
	const deleteActionState = useComponentState({ showDialog: false, announcment: null });

	const columns = useMemo(() => {
		return [
			{
				name: strings.announcements.announcmentPoster,
				cell: (row: any) => {
					return (
						<Row className={"p-2"} style={{ maxWidth: "300px" }}>
							<img
								className="img-responsive rounded-circle"
								style={{ width: "50px", height: "50px" }}
								src={row.image?.location}
								alt={row.title}
								onClick={() => {
									DialogUtil.showImageDialog(row.image?.location);
								}}
							/>
						</Row>
					);
				},
			},
			{
				name: strings.announcements.title,
				cell: (row: any) => {
					return row.title;
				},
			},
			{
				name: strings.announcements.url,

				cell: (row: any) => {
					return (
						<h5 className={"max-lines"}>
							<a href={row.url}>{row.url}</a>
						</h5>
					);
				},
			},
			{
				name: strings.announcements.publishDate,

				cell: (row: any) => {
					return <p style={{ direction: "ltr" }}>{DateTimeUtil.format(row.scheduled)}</p>;
				},
			},
			{
				name: strings.teamMembers.action,
				cell: (row: any, index: number) => {
					return (
						<Row key={row._id}>
							<Col>
								{AppUtil.isUserHaveAccess("/home/announcements/addEdit") && (
									<AppButton
										text={strings.edit}
										className={"btn btn-primary btn-trans waves-effect waves-primary mx-2 btn-sm"}
										type={"button"}
										onClick={() => {
											router.push({
												pathname: "/home/announcements/addEdit",
												search: "?id=" + row._id,
											});
										}}
									/>
								)}

								{AppUtil.isUserHaveAccess("/home/announcements/delete") && (
									<AppButton
										text={strings.delete}
										className={"btn btn-danger btn-trans waves-effect waves-danger btn-sm"}
										type={"button"}
										onClick={() => {
											deleteActionState.value = { showDialog: true, announcment: row };
										}}
									/>
								)}
							</Col>
						</Row>
					);
				},
			},
		];
	}, []);

	function deleteAnnouncment() {
		// 1 hide delete dialog
		deleteActionState.value = (oldState: any) => {
			return { ...oldState, showDialog: false };
		};
		//2 call delete member api.
		const request = new HttpRequest();
		request.url = `admin/v2/announcements/${deleteActionState.value.announcment._id}`;
		request.method = "DELETE";
		HttpService.send(request)
			.then((res) => {
				announcementsState.value = ArrayUtil.findByIdAndRemove(deleteActionState.value.announcment, announcementsState.value);
				allAnnouncmentsRef.current = ArrayUtil.findByIdAndRemove(deleteActionState.value.announcment, allAnnouncmentsRef.current as any[], false);
				ToastManager.showSuccess(res.data.message);
			})
			.catch((e) => {
				ToastManager.showError(e.message);
			});
	}

	function loadAnnouncments() {
		const request = new HttpRequest();
		request.method = "GET";
		request.url = "admin/v2/announcements";
		HttpService.send(request)
			.then((res) => {
				loadingInfoState.value = { isLoading: false, error: null };
				const announcements = (res.data as AnnouncmentResponse).announcements;
				announcementsState.value = announcements;
				allAnnouncmentsRef.current = announcements;
			})
			.catch((e) => {
				loadingInfoState.value = { isLoading: false, error: e.message };
			});
	}

	function filterAnnouncments(event: any) {
		const searchValue = event.target.value.trim();
		searchValueRef.current = searchValue;
		if (searchValue !== "") {
			// filter by announcment name.
			announcementsState.value = allAnnouncmentsRef.current?.filter((announcment) => {
				return announcment.title?.includes(searchValue);
			});
		} else {
			announcementsState.value = allAnnouncmentsRef.current;
		}
	}

	useEffect(() => {
		appStore.update((old) => {
			old.screenHeaderName = strings.dashboard.announcements;
			old.showBackButton = false;
		});
		loadAnnouncments();
	}, []);

	return (
		<Row className={"flex-column justify-content-center"}>
			{AppUtil.isUserHaveAccess("/home/announcements/addEdit") && (
				<Col>
					<AppButton
						text={strings.announcements.addNew}
						onClick={() => {
							router.push("/home/announcements/addEdit");
						}}
						margin={"m-2"}
					/>
				</Col>
			)}

			<IfCondition condition={loadingInfoState.value.isLoading}>
				<Col className={"text-center"}>
					<AppLoadingSpinner />
				</Col>
			</IfCondition>

			<IfCondition condition={loadingInfoState.value.error != null}>
				<Alert variant={"danger"}>{loadingInfoState.value.error}</Alert>
			</IfCondition>

			<IfCondition condition={!loadingInfoState.value.isLoading && loadingInfoState.value.error == null}>
				<Col>
					<Form.Control placeholder={strings.search} className={"my-2 w-25"} value={searchValueRef.current} onChange={filterAnnouncments} />
					<DataTable pagination={true} paginationPerPage={10} columns={columns} data={announcementsState.value} paginationResetDefaultPage={true} />
				</Col>
			</IfCondition>
			<WarningAlertDialog
				show={deleteActionState.value.showDialog}
				onCancel={() => {
					deleteActionState.value = (oldState: any) => {
						return { ...oldState, showDialog: false };
					};
				}}
				onConfirm={deleteAnnouncment}
				title={strings.alert.sureDelete}
			/>
		</Row>
	);
}
