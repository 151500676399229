import Swal from "sweetalert2";
import { strings } from "../translation/locale";

export default class DialogUtil {
    static showImageDialog(image: string) {
        Swal.fire({
            imageUrl: image,
            imageHeight: 400,
            confirmButtonText: strings.close,
        });
    }

    static showImageDialogWithTitle(image: string, title: string) {
        Swal.fire({
            imageUrl: image,
            imageHeight: 400,
            title: title,
            confirmButtonText: strings.close,
        });
    }
    static async showSendCommentReply(comment: any,replyTo:any) {
        const endpoint = `admin/v2/books/comments/replies/${comment._id}`;
        const { value: message } = await Swal.fire({
            input: "textarea",
            inputLabel: `${strings.replyToComment} ${replyTo.name}`,
            inputPlaceholder: strings.replayToCommentPlaceholder,
            inputAttributes: {
                "aria-label": strings.replayToCommentPlaceholder,
            },
            showCancelButton: true,
            cancelButtonText: strings.cancel,
            confirmButtonText: strings.send,
        });
        if (message) {
            return { message: { endpoint: endpoint, content: message,replyToId:replyTo._id } };
        }
        return { message: null };
    }
    
}
