import { Alert, Col, Form, Row } from "react-bootstrap";
import AppButton from "../../../components/AppButton";
import { strings } from "../../../translation/locale";
import { IfCondition } from "react-ifloop-ts";
import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import DataTable from "react-data-table-component";
import React, { useEffect, useMemo, useRef } from "react";
import useComponentState from "../../../hooks/useComponentState";
import { appStore } from "../../../data/store/AppStore";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import { TimeSpent, TimeSpentResponse } from "../../../data/model/TimeSpentResponse";
import AppUtil from "../../../util/AppUtil";
import FromToDateFilter from "./FromToDateFilter";
import DateTimeUtil from "../../../util/DateTimeUtil";
import $ from "jquery";

export default function TimeSpentScreen() {
      const loadingInfoState = useComponentState({ isLoading: false, error: null });
      const timeSpentState = useComponentState([]);
      const allTimeSpentRef = useRef<TimeSpent[]>();
      const searchValueRef = useRef<string>("");
      const sortByOrderRef = useRef("desc");
      const fromDateToDateRef = useRef({ fromDate: "", toDate: "" });

      const columns = useMemo(() => {
            return [
                  {
                        name: strings.timeSpent.bookTitle,
                        selector: (row: any) => row.bookTitle,
                  },
                  {
                        name: strings.books.subtitle,
                        selector: (row: any) => row.bookSubtitle,
                  },
                  {
                        name: strings.timeSpent.bookAuthor,
                        selector: (row: any) => row.bookAuthor.name,
                  },
                  {
                        name: strings.books.publisher,
                        selector: (row: any) => row.bookPublisher.name || "غير معروف",
                  },
                  {
                        name: strings.books.publisherRatio,
                        selector: (row: any) => row.bookPublisherRatio || 0,
                  },
                  {
                        name: strings.timeSpent.timeSpent,
                        sortable: true,
                        cell: (row: any, index: number) => {
                              return <h5 style={{ direction: "ltr" }}>{AppUtil.kFormatter(row.timeSpent)}</h5>;
                        },
                  },
            ];
      }, []);

      function loadTimeSpent(fromDate: string, toDate: string) {
            if (!fromDate || !toDate) {
                  return;
            }
            loadingInfoState.value = { isLoading: true, error: null };

            fromDateToDateRef.current = { fromDate: fromDate, toDate: toDate };
            const from = DateTimeUtil.format(fromDate, "YYYY-M");
            const to = DateTimeUtil.format(toDate, "YYYY-M");
            const request = new HttpRequest();
            request.method = "GET";
            request.url = "/admin/v2/timeSpent";
            request.queryParams = { fromDate: from, toDate: to };
            HttpService.send(request)
                  .then((res) => {
                        loadingInfoState.value = { isLoading: false, error: null };
                        const timeSpent = (res.data as TimeSpentResponse).timeSpent;
                        timeSpentState.value = timeSpent;
                        allTimeSpentRef.current = timeSpent;
                  })
                  .catch((e) => {
                        loadingInfoState.value = { isLoading: false, error: e.message };
                  });
      }

      function filterTimeSpent(event: any) {
            const searchValue = event.target.value;
            searchValueRef.current = searchValue;
            if (searchValue !== "") {
                  // filter by author name.
                  timeSpentState.value = allTimeSpentRef.current?.filter((timeSpent) => {
                        return (
                              timeSpent.bookTitle.includes(searchValue) ||
                              timeSpent.bookSubtitle.includes(searchValue) ||
                              timeSpent.bookAuthor.name.includes(searchValue)
                        );
                  });
            } else {
                  timeSpentState.value = allTimeSpentRef.current;
            }
      }

      function exportExcel() {
            if (!fromDateToDateRef.current.fromDate || !fromDateToDateRef.current.toDate) {
                  return;
            }
            loadingInfoState.value = { isLoading: true, error: null };

            const from = DateTimeUtil.format(fromDateToDateRef.current.fromDate, "YYYY-M");
            const to = DateTimeUtil.format(fromDateToDateRef.current.toDate, "YYYY-M");
            const request = new HttpRequest();

            request.method = "GET";
            request.url = "/admin/v2/timeSpent/exportExcel";
            request.queryParams = { fromDate: from, toDate: to, filter: searchValueRef.current };
            request.responseType = "blob";
            HttpService.send(request)
                  .then((res) => {
                        const a: any = document.createElement("a");
                        document.body.appendChild(a);
                        a["style"] = "display: none";
                        const url = window.URL.createObjectURL(res.data);
                        a.href = url;
                        a.download = `Audiohat-Report_${to}.csv`; //"timeSpentReport.csv";
                        a.click();
                        window.URL.revokeObjectURL(url);

                        loadingInfoState.value = { isLoading: false, error: null };
                  })
                  .catch((e) => {
                        loadingInfoState.value = { isLoading: false, error: e.message };
                  });
      }

      function sortReadSpentTime() {
            const order = sortByOrderRef.current;
            if (order === "asc") {
                  // sort desc.
                  timeSpentState.value = (old: TimeSpent[]) => {
                        old.sort(function (a: TimeSpent, b: TimeSpent) {
                              return b.timeSpent - a.timeSpent;
                        });
                        return [...old];
                  };
                  sortByOrderRef.current = "desc";
            } else {
                  // sort asc.
                  timeSpentState.value = (old: TimeSpent[]) => {
                        old.sort(function (a: TimeSpent, b: TimeSpent) {
                              return a.timeSpent - b.timeSpent;
                        });
                        return [...old];
                  };
                  sortByOrderRef.current = "asc";
            }
      }

      useEffect(() => {
            $("div#column-3").off("click");
            appStore.update((old) => {
                  old.screenHeaderName = strings.dashboard.timeSpent;
                  old.showBackButton = false;
            });

            $(document).on("click", "div#column-3", () => {
                  sortReadSpentTime();
            });
      }, []);

      useEffect(() => {
            const today = new Date();
            const nowDateStr = DateTimeUtil.format(today, "YYYY-MM-DD");

            const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
            const firstDateStr = DateTimeUtil.format(firstDate, "YYYY-MM-DD");

            loadTimeSpent(firstDateStr.toString(), nowDateStr.toString());
      }, []);

      return (
            <Row className={"flex-column justify-content-center"}>
                  <IfCondition condition={loadingInfoState.value.isLoading}>
                        <Col className={"text-center"}>
                              <AppLoadingSpinner />
                        </Col>
                  </IfCondition>

                  <IfCondition condition={loadingInfoState.value.error != null}>
                        <Alert variant={"danger"}>{loadingInfoState.value.error}</Alert>
                  </IfCondition>
                  <FromToDateFilter
                        onCalcClick={(fromDate, toDate) => {
                              loadTimeSpent(fromDate, toDate);
                        }}
                  />
                  <Col>
                        <Row>
                              <Col md={3}>
                                    <Form.Control
                                          placeholder={strings.search}
                                          className={"my-2"}
                                          value={searchValueRef.current}
                                          onChange={filterTimeSpent}
                                    />
                              </Col>
                              <Col className={"mt-md-2"}>
                                    <AppButton
                                          type={"button"}
                                          text={strings.timeSpent.exportExcel}
                                          onClick={exportExcel}
                                    />
                              </Col>
                        </Row>
                        <DataTable
                              noDataComponent={<h3>برجاء إختيار الفترة الزمنية المراد عرض الإحصائيات الخاصة بها</h3>}
                              pagination={true}
                              paginationPerPage={30}
                              responsive={true}
                              columns={columns}
                              data={timeSpentState.value}
                              paginationResetDefaultPage={true}
                              sortIcon={<i className="mdi mdi-sort" />}
                        />
                  </Col>
            </Row>
      );
}
