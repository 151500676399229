import { strings } from "../../translation/locale";

const homeItems = [
      { key: 1, title: strings.dashboard.books, icon: "book-open-page-variant", path: "/home/books" },
      { key: 2, title: strings.dashboard.bookComments, icon: "comment-text-multiple", path: "/home/book-commetns" },
      { key: 13, title: strings.dashboard.booksReorder, icon: "sort-alphabetical", path: "/home/book-reorder" },
      { key: 12, title: strings.dashboard.series, icon: "library", path: "/home/serieses" },
      { key: 3, title: strings.dashboard.announcments, icon: "bullhorn-outline", path: "/home/announcements" },
      { key: 6, title: strings.dashboard.authors, icon: "book", path: "/home/authors" },
      { key: 8, title: strings.dashboard.categories, icon: "bookmark", path: "/home/categories" },
      { key: 9, title: strings.dashboard.users, icon: "account-circle", path: "/home/users" },
      { key: 10, title: strings.dashboard.admins, icon: "account-circle", path: "/home/admins" },
      { key: 4, title: strings.dashboard.settings, icon: "settings", path: "/home/settings", justView: true },
      { key: 5, title: strings.dashboard.teamMembers, icon: "account-multiple", path: "/home/teamMembers" },
      { key: 7, title: strings.dashboard.pages, icon: "web", path: "/home/pages" },
      { key: 11, title: strings.dashboard.timeSpent, icon: "chart-bar", path: "/home/timeSpent", justView: true },
      //{ key: 14, title: strings.dashboard.backup, icon: "backup-restore", path: "/home/backup", justView: true },
      { key: 14, title: strings.dashboard.files, icon: "file-chart", path: "/home/files" },
];

export default homeItems;
