
import { useEffect } from "react";
import Select from 'react-select'
import { Alert, Card, Col, Row, Form } from "react-bootstrap";
import { IfCondition } from "react-ifloop-ts";
import { useFormik } from "formik";
import * as Yup from "yup";

import useQueryParams from "../../../hooks/useQueryParams";
import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import { strings } from "../../../translation/locale";
import AppTextField from "../../../components/AppTextField";
import AppButton from "../../../components/AppButton";
import useComponentState from "../../../hooks/useComponentState";

import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import ToastManager from "../../../util/ToastManager";
import AppFileField from "../../../components/AppFileField";

import { appStore } from "../../../data/store/AppStore";


export default function AddEditseriescreen() {
    const queryParams = useQueryParams();
    const loading = useComponentState(false);
    const seriesInfoState = useComponentState({ image:{} });
    const booksState = useComponentState([]);
    const idState = useComponentState("");

    function mapBooksToSelectOptions(books: any[]){
        if(!books) return [];
        return books.map(book => {
            return {
                value: book._id,
                label: book.title
            }
        });
    }
    function mapSelectedBooksToIds(books: any[]){
        return books.map(book => {
            return book.value;
        });
    }

    function loadSeriesDetails(seriesId: any) {
        loading.value = true;
        const request = new HttpRequest();
        request.method = "GET";
        request.url = `admin/v2/serieses/${seriesId}`;
        HttpService.send(request)
            .then((res) => {
                loading.value = false;
                seriesInfoState.value = {
                    title: res.data.series.title,
                    description: res.data.series.description,
                    image: res.data.series.image,
                    books: mapBooksToSelectOptions(res.data.series.books)
                };
            })
            .catch((e) => {
                loading.value = false;
                seriesInfoState.value = (old: any) => {
                    old.loadDetailsError = e.message;
                    return { ...old };
                };
            });
    }

    useEffect(() => {
        appStore.update((old) => {
            old.showBackButton = true;
        });

        if (queryParams.id) {
            // load series details.
            idState.value = queryParams.id;
            loadSeriesDetails(queryParams.id);
        }else{
            // create new series.
            idState.value = "new";
        }
        loadAllBooks();
    }, [queryParams]);

    function loadAllBooks() {
        const request = new HttpRequest();
        request.method = "GET";
        request.url = "/admin/v2/serieses/get-books";
        HttpService.send(request).then(res => {
            booksState.value = mapBooksToSelectOptions(res.data.books);
        }).catch(e => {
            ToastManager.showError(e.message);
        });
    }
    
    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        initialValues: {
            title: seriesInfoState.value.title,
            description: seriesInfoState.value.description,
            image:seriesInfoState.value.image,
            books:seriesInfoState.value.books,
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().min(2, strings.formatString(strings.validation.minFieldChars, 2)).required(strings.validation.required),
            image:
                idState.value === "new"
                    ? Yup.mixed().test("file", strings.validation.required, (value) => {
                          return value != null;
                      })
                    : Yup.object().nullable(),
        }),
        async onSubmit(values) {
            // call web service.
            const request = new HttpRequest();
            let formData = new FormData();
            if (values.title) {
                formData.append("title", values.title);
            }
            if (values.description) {
                formData.append("description", values.description);
            }
            
            if (values.image != null) {
                formData.append("image", values.image);
            }
            if (values.books && values.books.length > 0) {
                const books = mapSelectedBooksToIds(values.books);
                books.forEach((book) => {
                    formData.append("books[]", book);
                });
            }
            if (idState.value !== "new") {
                // edit series
                request.url = `admin/v2/serieses/${idState.value}`;
                request.method = "PUT";
            } else {
                // add new series.
                request.url = `admin/v2/serieses`;
                request.method = "POST";
            }
            request.body = formData;
            loading.value = true;

            HttpService.send(request)
                .then((response) => {
                    loading.value = false;
                    idState.value = response.data.series._id;
                    ToastManager.showSuccess(response.data.message);
                    //router.push("/home/serieses");
                })
                .catch((e) => {
                    loading.value = false;
                    ToastManager.showError(e.message);
                });
        },
    });

    return (
        <Row className={"flex-column justify-content-center"}>
            <IfCondition condition={loading.value}>
                <Col className={"text-center"}>
                    <AppLoadingSpinner />
                </Col>
            </IfCondition>
            <IfCondition condition={seriesInfoState.value.loadDetailsError != null}>
                <Alert variant={"danger"}>{seriesInfoState.value.loadDetailsError}</Alert>
            </IfCondition>
            <Col>
                <Card style={{minHeight:"100vh"}}>
                    <Card.Header className={"text-center"}>{idState.value !=="new" ? strings.edit : strings.addNew}</Card.Header>
                    <Card.Body>
                        <form onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col md={8}>
                                    <AppTextField type={"text"} name={"title"} formik={formik} placeholder={strings.series.seriesTitle}/>
                                    <AppTextField type={"text"} name={"description"} formik={formik} placeholder={strings.series.seriesSummary} as={'textarea'} rows={9}/>

                                </Col>
                                <Col md={4}>
                                <AppFileField
                                            name={"image"}
                                            placeholder={strings.series.seriesPhoto}
                                            onFilePicked={(file) => {
                                                seriesInfoState.value = ((old: any) => {
                                                    old.image.location = URL.createObjectURL(file);
                                                    return {...old};
                                                });
                                                formik.setFieldValue("image", file);
                                            }}
                                            formik={formik}
                                        />
                                    <IfCondition condition={seriesInfoState.value.image}>
                                        <img
                                            className="img-responsive"
                                            style={{ width: "100%", height: "auto" }}
                                            src={seriesInfoState.value.image?.location}
                                            alt={seriesInfoState.value.title}
                                        />
                                    </IfCondition>
                                </Col>
                            </Row>
                            <hr />
                            <Row>

                            <Col md={2}>
                            <Form.Label htmlFor={'books'}>{strings.series.books}</Form.Label>

                            </Col>
                            <Col>
                            <Form.Group>
                                            <Select options={booksState.value} value={formik.values.books}
                                                    closeMenuOnSelect={false}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    styles={{
                                                        menu: provided => ({...provided, zIndex: 9999})
                                                    }}

                                                    isMulti={true}
                                                    onChange={(value: any) => {
                                                        formik.setFieldValue("books", value);
                                                    }
                                                    }/>
                                            <IfCondition condition={formik.errors['books'] != null}>
                                                <Form.Text className={"text-danger"}>{formik.errors["books"]}</Form.Text>
                                            </IfCondition>
                                        </Form.Group></Col>
                            </Row>

                            <AppButton text={strings.save} type={"submit"} disabled={loading.value} />
                        </form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}