import {strings} from "../../../translation/locale";
import {Form} from "react-bootstrap";
import useComponentState from "../../../hooks/useComponentState";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import ToastManager from "../../../util/ToastManager";

export default function OrderInput(props: any) {
    const order = useComponentState(props.order);

    function updateOrder(event: any) {
        const newMemberOrder = event.target.value;
        const request = new HttpRequest();
        request.url = `/admin/v2/teamMembers/${props.id}/orders`;
        request.method = "PUT";
        request.body = {order: newMemberOrder};
        HttpService.send(request).then((res) => {
            ToastManager.showSuccess(res.data.message);
            order.value = newMemberOrder;
        }).catch((e) => {
            ToastManager.showError(e.message);
        });
    }

    return <Form.Control placeholder={strings.settings.order} type={'number'} value={order.value}
                         onChange={updateOrder} style={{width: '100px'}} min={1}/>
}