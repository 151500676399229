import React from "react";
import { Modal, Card, Form } from "react-bootstrap";
import { User } from "../../data/model/UsersResponse";
import { strings } from "../../translation/locale";

interface Props {
	user: User | undefined;
	show: boolean;
	handleClose: () => void;
	handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	notification: { content: string; url: string };
	handleSendNotification: () => void;
}

export default class SendNotificationModal extends React.Component<Props> {
	render() {
		const { show, user, handleClose, notification, handleInputChange, handleSendNotification } = this.props;

		return (
			<Modal
				size="lg"
				show={show}
				backdrop="static"
				centered={true}
				keyboard={false}
				onHide={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{strings.users.sendNotificationToUser} {user?.name}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Card>
						<Card.Body style={{ textAlign: "right" }}>
							<Form.Group>
								<Form.Control
									as="textarea"
									rows={5}
									name={"content"}
									placeholder={strings.users.notificationContent}
									onChange={handleInputChange}
									value={notification?.content}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Control
									type="url"
									name={"url"}
									placeholder={strings.users.optionalURL}
									onChange={handleInputChange}
									value={notification?.url}
								/>
							</Form.Group>
						</Card.Body>
					</Card>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<button onClick={handleSendNotification} className="btn btn-success w-200px fs2rem ml-5">
						{strings.send}
					</button>
					<button onClick={handleClose} className="btn btn-info w-200px fs2rem">
						{strings.cancel}
					</button>
				</Modal.Footer>
			</Modal>
		);
	}
}
