import { Alert, Col, Row } from "react-bootstrap";
import useComponentState from "../../../hooks/useComponentState";
import { ElseIfCondition, IfCondition, IfMultiCondition } from "react-ifloop-ts";
import { useEffect } from "react";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import ToastManager from "../../../util/ToastManager";
import axios from "axios";
import { Line } from "rc-progress";

interface Props {
      file: any;
      onFileUploaded: (audioFile: any) => void;
      time: string;
}

export default function UploadFileComponent(props: Props) {
      const uploadState = useComponentState({ percent: 0, error: null });
      const signedUrlState = useComponentState(null);

      useEffect(() => {
            const request = new HttpRequest();
            request.method = "GET";
            request.url = "admin/v2/uploads/signedUrl?fileName=" + props.file.name;
            HttpService.send(request)
                  .then((res) => {
                        const url = res.data.signedUrl.url;
                        signedUrlState.value = url;
                        upload(url);
                  })
                  .catch((e) => {
                        ToastManager.showError(e.message);
                  });
      }, [props.time]);

      async function upload(signedUrl: string) {
            const axiosInstance = axios.create({});
            axiosInstance
                  .request({
                        method: "PUT",
                        url: signedUrl,
                        data: props.file,
                        onUploadProgress: (p) => {
                              uploadState.value = { percent: Math.floor((p.loaded / p.total) * 100), error: null };
                        },
                  })
                  .then((response) => {
                        const audioFile: any = {
                              originalname: props.file.name,
                              mimetype: props.file.type,
                              size: props.file.size,
                              duration: 0,
                        };
                        const index = signedUrl.indexOf(props.file.name) + props.file.name.length;
                        audioFile.location = signedUrl.substring(0, index);
                        props.onFileUploaded(audioFile);
                        uploadState.value = { percent: 100, error: null };
                  })
                  .catch((e) => {
                        uploadState.value = { percent: 0, error: e.message };
                  });
      }

      return (
            <IfCondition condition={signedUrlState.value != null}>
                  <Row>
                        <Col>
                              <IfMultiCondition>
                                    <IfCondition condition={uploadState.value.error == null}>
                                          <Row>
                                                <Col>
                                                      <h2 className={"text-center my-1 text-left"}>
                                                            {uploadState.value.percent + " %"}
                                                      </h2>
                                                </Col>
                                                <Line
                                                      percent={uploadState.value.percent}
                                                      trailWidth={1}
                                                      strokeWidth={1}
                                                      strokeColor="#4A59B4"
                                                      className={"my-1 mx-2"}
                                                />
                                          </Row>
                                    </IfCondition>
                                    <ElseIfCondition condition={uploadState.value.error != null}>
                                          <Alert variant={"danger"} className={"text-center"}>
                                                {uploadState.value.error}
                                          </Alert>
                                    </ElseIfCondition>
                              </IfMultiCondition>
                        </Col>
                  </Row>
            </IfCondition>
      );
}
