import useQueryParams from "../../../hooks/useQueryParams";
import {Alert, Card, Col, Row} from "react-bootstrap";
import {IfCondition} from "react-ifloop-ts";
import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import {strings} from "../../../translation/locale";
import AppTextField from "../../../components/AppTextField";
import AppButton from "../../../components/AppButton";
import useComponentState from "../../../hooks/useComponentState";
import {useFormik} from "formik";
import * as Yup from "yup";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import ToastManager from "../../../util/ToastManager";
import AppFileField from "../../../components/AppFileField";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {appStore} from "../../../data/store/AppStore";

export default function AddEditCategoryScreen() {
    const queryParams = useQueryParams();
    const loading = useComponentState(false);
    const router = useHistory();
    const categoryInfoState = useComponentState({label: "", icon: null, photo: null, loadDetailsError: null});

    function loadCategoryDetails(categoryId: any) {
        loading.value = true;
        const request = new HttpRequest();
        request.method = "GET";
        request.url = `admin/v2/categories/${categoryId}`;
        HttpService.send(request).then((res) => {
            loading.value = false;
            categoryInfoState.value = {
                label: res.data.category.label, icon: res.data.category.icon, photo: null, loadDetailsError: null
            };
        }).catch((e) => {
            loading.value = false;
            categoryInfoState.value = ((old: any) => {
                old.loadDetailsError = e.message;
                return {...old};
            })
        });
    }

    useEffect(() => {
        appStore.update((old) => {
            old.showBackButton = true;
        });

        if (queryParams.id) {
            // load category details.
            loadCategoryDetails(queryParams.id);
        }
    }, [queryParams]);
    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        initialValues: {
            label: categoryInfoState.value.label,
            icon: categoryInfoState.value.photo
        },
        validationSchema: Yup.object().shape({
            label: Yup.string().min(2, strings.formatString(strings.validation.minFieldChars, 2)).required(strings.validation.required),
            icon: queryParams.id == null ? Yup.mixed().test('file', strings.validation.required
                , (value) => {
                    return value != null;
                }) : Yup.object().nullable(),
        }),
        async onSubmit(values) {
            // call web service.
            const request = new HttpRequest();
            let formData = new FormData();
            if (values.label) {
                formData.append("label", values.label);
            }
            if (values.icon != null) {
                formData.append("icon", categoryInfoState.value.photo);
            }
            if (queryParams.id) {
                // edit category
                request.url = `admin/v2/categories/${queryParams.id}`;
                request.body = formData;
                request.method = 'PUT';
                loading.value = true;
            } else {
                // add new category
                request.url = `admin/v2/categories`;
                request.body = formData;
                request.method = 'POST';
                loading.value = true;
            }

            HttpService.send(request).then((response) => {
                loading.value = false;
                ToastManager.showSuccess(response.data.message);
                router.push("/home/categories");
            }).catch((e) => {
                loading.value = false;
                ToastManager.showError(e.message);
            });
        },
    });

    return <Row className={"flex-column justify-content-center"}>
        <IfCondition condition={loading.value}>
            <Col className={'text-center'}><AppLoadingSpinner/></Col>
        </IfCondition>
        <IfCondition condition={categoryInfoState.value.loadDetailsError != null}>
            <Alert variant={'danger'}>{categoryInfoState.value.loadDetailsError}</Alert>
        </IfCondition>
        <Col>
            <Card>
                <Card.Header className={"text-center"}>
                    {queryParams.id ? strings.edit : strings.addNew}
                </Card.Header>
                <Card.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <AppTextField type={'text'} name={'label'} formik={formik} placeholder={strings.categories.label}/>

                        <IfCondition condition={categoryInfoState.value.icon != null}>
                            <img className="img-responsive rounded-circle" style={{width: "80px", height: "80px"}}
                                 src={categoryInfoState.value.icon?.location} alt={categoryInfoState.value.label}/>
                        </IfCondition>

                        <AppFileField name={'icon'} placeholder={strings.teamMembers.photo} onFilePicked={(file) => {
                            categoryInfoState.value = ((old: any) => {
                                old.photo = file;
                                return {...old};
                            });
                        }
                        } formik={formik}/>
                        <AppButton text={strings.save} type={'submit'} disabled={loading.value}/>
                    </form>
                </Card.Body>
            </Card>
        </Col>
    </Row>
}