import { Col, Form, Row } from "react-bootstrap";
import { useEffect } from "react";

import useComponentState from "../../../hooks/useComponentState";
import AppButton from "../../../components/AppButton";
import { strings } from "../../../translation/locale";
import DateTimeUtil from "../../../util/DateTimeUtil";

import "./style.css";

interface Props {
    onCalcClick: (fromDate: string, toDate: string) => void;
}

export default function FromToDateFilter(props: Props) {
    const fromDateState = useComponentState("");
    const toDateState = useComponentState("");

    useEffect(() => {
        const today = new Date();
        const nowDateStr = DateTimeUtil.format(today, "YYYY-MM-DD");

        const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
        const firstDateStr = DateTimeUtil.format(firstDate, "YYYY-MM-DD");

        fromDateState.value = firstDateStr;
        toDateState.value = nowDateStr;
    }, []);

    return (
        <Row>
            <Col md={3}>
                <Form.Group>
                    <Form.Label htmlFor={"fromDate"}>{strings.timeSpent.fromDate}</Form.Label>
                    <Form.Control
                        type={"date"}
                        id={"fromDate"}
                        name={"fromDate"}
                        value={fromDateState.value}
                        onChange={(e) => {
                            fromDateState.value = e.target.value;
                        }}
                    />
                </Form.Group>
            </Col>
            <Col md={3}>
                <Form.Group>
                    <Form.Label htmlFor={"toDate"}>{strings.timeSpent.toDate}</Form.Label>
                    <Form.Control
                        type={"date"}
                        id={"toDate"}
                        name={"toDate"}
                        value={toDateState.value}
                        onChange={(e) => {
                            toDateState.value = e.target.value;
                        }}
                    />
                </Form.Group>
            </Col>
            <Col className={"mt-md-rem"}>
                <AppButton
                    text={strings.timeSpent.calc}
                    onClick={() => {
                        props.onCalcClick(fromDateState.value, toDateState.value);
                    }}
                    type={"button"}
                />
            </Col>
        </Row>
    );
}
