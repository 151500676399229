import { Redirect, Route } from "react-router-dom";
import UserCache from "../data/cache/UserCache";
// access denied screen
import AccessDeniedScreen from "../screens/AccessDeniedScreen";
import AppUtil from "../util/AppUtil";

export default function MustBeLoginAuth(props: any) {
    const user = UserCache.getUser();
    if (!user) {
        return <Redirect to={"/auth/login"} />;
    }

    const userCaps = user?.caps ? user.caps : [];
    const havAccess = AppUtil.isUserHaveAccess(props.location.pathname);

    if (havAccess || props.location.pathname === "/auth/login") {
        return <Route {...props} />;
    } else if (userCaps.includes("/home/books") && (props.location.pathname === "/" || props.location.pathname === "/home")) {
        return <Redirect to={"/home/books"} />;
    } else if (userCaps.length > 0) {
        return <Redirect to={userCaps[0]} />;
    } else {
        return <AccessDeniedScreen user={user} {...props} />;
    }
}
