import Pagination from "react-responsive-pagination";
import { useEffect, useRef, useMemo } from "react";
import UserCache from "../../data/cache/UserCache";

import { appStore } from "../../data/store/AppStore";
import { strings } from "../../translation/locale";
import useComponentState from "../../hooks/useComponentState";
import { Alert, Col, Form, Row, Table } from "react-bootstrap";
import { ForLoop, IfCondition } from "react-ifloop-ts";
import AppLoadingSpinner from "../../components/AppLoadingSpinner";
import { User, UsersResponse } from "../../data/model/UsersResponse";
import WarningAlertDialog from "../../components/WarningAlertDialog";
import NewAdminDialog from "./NewAdminDialog";

import HttpRequest from "../../data/network/HttpRequest";
import HttpService from "../../data/network/HttpService";
import { useDebounce } from "use-debounce";
import DialogUtil from "../../util/DialogUtil";
import AppButton from "../../components/AppButton";
import ToastManager from "../../util/ToastManager";
import homeItems from "../home/homeItems";
import AppUtil from "../../util/AppUtil";

export default function AdminsScreen() {
  const admin = useMemo(() => {
    return UserCache.getUser();
  }, []);
  const loadingState = useComponentState({ isLoading: true, error: null });
  const usersRef = useRef<any>({ limit: 50, users: [], total: 0, currentPage: 1, totalPages: 0 });
  const searchFiledState = useComponentState("");
  const [searchText] = useDebounce(searchFiledState.value, 1000);
  const usersCountRef = useRef(0);
  const deleteActionState = useComponentState({ showDialog: false, user: null });
  const addNewAdminActionState = useComponentState({ showDialog: false });
  //const editAdminActionState = useComponentState({ showDialog: false, user: null });
  const caps = [
    { id: 1, title: strings.show, path: "", className: "info", fontClass: "text-dark" },
    {
      id: 2,
      title: `${strings.create}/${strings.edit}`,
      path: "addEdit",
      className: "warning",
      fontClass: "text-dark",
    },
    { id: 4, title: strings.delete, path: "delete", className: "danger", fontClass: "text-light" },
  ];
  useEffect(() => {
    appStore.update((state) => {
      state.screenHeaderName = strings.dashboard.admins;
    });
  }, []);

  useEffect(() => {
    usersRef.current = { limit: 50, offset: 0, total: 0, currentPage: 1, totalPages: 0, users: [] };
    loadUsers();
  }, [searchText]);

  function filterUsers(event: any) {
    searchFiledState.value = event.target.value;
  }

  function loadMoreUsers(pageNumber: number) {
    usersRef.current.currentPage = pageNumber;
    loadUsers();
  }

  function loadUsers() {
    loadingState.value = { isLoading: true, error: null };
    const request = new HttpRequest();
    request.method = "GET";
    request.url = "/admin/v2/users/admins";
    let offset = 0;
    if (usersRef.current.currentPage > 1) {
      offset = (usersRef.current.currentPage - 1) * usersRef.current.limit;
    }
    let query: any = { offset: offset, limit: usersRef.current.limit };
    if (searchText !== "") {
      query["q"] = searchText;
    }
    request.queryParams = query;

    HttpService.send(request)
      .then((res) => {
        const usersResults = (res.data as UsersResponse).usersResults;

        usersRef.current.users = usersResults.users;
        if (usersResults.total > -1) {
          usersRef.current.total = usersResults.total;
          usersRef.current.totalPages = Math.round(usersResults.total / usersRef.current.limit);
          usersCountRef.current = usersResults.total;
          appStore.update((state) => {
            state.screenHeaderName = strings.admins.admins + " (" + usersResults.total + ") " + strings.admins.admin;
          });
        }

        loadingState.value = { isLoading: false, error: null };
      })
      .catch((e) => {
        loadingState.value = { isLoading: false, error: e.message };
      });
  }

  function deleteAdmin() {
    // 1 hide delete dialog
    deleteActionState.value = (oldState: any) => {
      return { ...oldState, showDialog: false };
    };
    // //2 call delete member api.
    const request = new HttpRequest();
    request.url = `/admin/v2/users/admins/${deleteActionState.value.user._id}/delete`;
    request.method = "PUT";
    HttpService.send(request)
      .then((res) => {
        loadUsers();
      })
      .catch((e) => {
        ToastManager.showError(e.message);
      });
  }

  function checkCap(user: User, curren: string) {
    if (!user.caps || user.caps.includes(curren)) {
      return true;
    }
    return false;
  }
  function checkAllCapsForPath(user: User, path: string) {
    if (!user.caps) return false;
    if (
      user.caps.includes(`${path}/`) &&
      user.caps.includes(`${path}/addEdit`) &&
      user.caps.includes(`${path}/delete`)
    ) {
      return true;
    }
    return false;
  }
  function updateAllCapsForPath(user: User, path: string, checked: boolean) {
    let caps: string[] = user.caps ? user.caps : [];

    if (checked) {
      [`${path}/`, `${path}/addEdit`, `${path}/delete`].forEach((cap) => {
        if (!caps.includes(cap)) {
          caps.push(cap);
        }
      });
    } else {
      [`${path}/`, `${path}/addEdit`, `${path}/delete`].forEach((cap) => {
        if (caps.includes(cap)) {
          caps.splice(caps.indexOf(cap), 1);
        }
      });
    }

    loadingState.value = { isLoading: true, error: null };

    const request = new HttpRequest();
    request.url = `/admin/v2/users/admins/${user._id}/update_caps`;
    request.method = "PUT";
    request.body = { caps: caps };
    HttpService.send(request)
      .then((res) => {
        loadUsers();
      })
      .catch((e) => {
        ToastManager.showError(e.message);
        loadingState.value = { isLoading: false, error: null };
      });
  }
  function checkCapDisabled(user: User) {
    if (!AppUtil.isUserHaveAccess("/home/admins/addEdit")) {
      return true;
    }
    if (admin?.email.toLowerCase() !== user.email.toLowerCase()) {
      return false;
    }
    return true;
  }
  function updateAllCaps(user: User, caps: string[]) {
    loadingState.value = { isLoading: true, error: null };

    const request = new HttpRequest();
    request.url = `/admin/v2/users/admins/${user._id}/update_caps`;
    request.method = "PUT";
    request.body = { caps: caps };
    HttpService.send(request)
      .then((res) => {
        loadUsers();
      })
      .catch((e) => {
        ToastManager.showError(e.message);
        loadingState.value = { isLoading: false, error: null };
      });
  }

  function updateCaps(user: User, item: any, event: any) {
    loadingState.value = { isLoading: true, error: null };

    const allUsers = [...usersRef.current.users];

    const foundUser = allUsers.find((u: User) => u._id === user._id);
    if (foundUser) {
      if (!foundUser.caps) {
        foundUser.caps = [];
      }
      const finalCaps = [...foundUser.caps];
      if (finalCaps.includes(item)) {
        const index = finalCaps.indexOf(item);
        if (index > -1) {
          finalCaps.splice(index, 1);
        }
      } else {
        finalCaps.push(item);
      }

      foundUser.caps = finalCaps;
      usersRef.current.users = allUsers;

      const request = new HttpRequest();
      request.url = `/admin/v2/users/admins/${foundUser._id}/update_caps`;
      request.body = { caps: finalCaps };

      request.method = "PUT";
      HttpService.send(request)
        .then((res) => {
          loadingState.value = { isLoading: false, error: null };
        })
        .catch((e) => {
          loadingState.value = { isLoading: false, error: e.message };
          ToastManager.showError(e.message);
        });
    }
  }
  function showSkipCap(itemPath: string, capPath: string) {
    const skipPathes = ["/home/settings", "/home/users", "/home/timeSpent"];
    const skipCaps = ["addEdit", "delete"];

    if (skipPathes.includes(itemPath) && skipCaps.includes(capPath)) {
      return true;
    }

    return false;
  }
  return (
    <Row className={"flex-column justify-content-center"}>
      <IfCondition condition={loadingState.value.isLoading}>
        <Col className={"text-center"}>
          <AppLoadingSpinner />
        </Col>
      </IfCondition>

      <IfCondition condition={loadingState.value.error != null}>
        <Alert variant={"danger"}>{loadingState.value.error}</Alert>
      </IfCondition>

      <Col>
        {AppUtil.isUserHaveAccess("/home/admins/addEdit") && (
          <div className="d-flex">
            <AppButton
              text={strings.admins.addNew}
              onClick={() => {
                addNewAdminActionState.value = { showDialog: true };
              }}
              margin={"m-2 mx-4"}
            />
            <Form.Control
              placeholder={strings.search}
              className={"my-2 w-25"}
              value={searchFiledState.value}
              onChange={filterUsers}
            />
          </div>
        )}

        <div className={"card-box"}>
          <Table striped bordered responsive size={"md"}>
            <thead>
              <tr>
                <th>{strings.users.name}</th>
                <th>{strings.users.email}</th>
                <th>{strings.admins.caps}</th>
                <th>{strings.teamMembers.action}</th>
              </tr>
            </thead>
            <tbody>
              <ForLoop
                items={usersRef.current.users}
                forEachItem={(user: User, index: number) => {
                  const userImage = user.image
                    ? user.image.location
                    : "https://wp.audiohatdar.com/wp-content/uploads/2021/06/person-placeholder.jpg";
                  return (
                    <tr key={user._id} className={"p-0"}>
                      <td className="align-middle">
                        <Row
                          className={"p-2"}
                          onClick={() => {
                            DialogUtil.showImageDialog(userImage);
                          }}>
                          <img
                            className="img-responsive rounded-circle"
                            style={{ width: "40px", height: "40px" }}
                            src={userImage}
                            alt={user.name}
                          />
                          <h4 className={"mx-2"}>{user.name}</h4>
                        </Row>
                      </td>
                      <td className="align-middle" style={{ width: "250px" }}>
                        {user.email}
                      </td>
                      <td className="align-middle">
                        {checkCapDisabled(user) && <h3>لا يمكن تغيير الصلاحيات </h3>}
                        {!checkCapDisabled(user) &&
                          homeItems.map((item) => {
                            return (
                              <div key={item.key} className="row">
                                <div className="col-md-3">
                                  <input
                                    type="checkbox"
                                    checked={checkAllCapsForPath(user, `${item.path}`)}
                                    onChange={(e) => {
                                      updateAllCapsForPath(user, `${item.path}`, e.target.checked);
                                    }}
                                  />{" "}
                                  {item.title}:
                                </div>
                                <div className="col-md-9">
                                  {caps.map((cap) => {
                                    if (showSkipCap(item.path, cap.path)) {
                                      return null;
                                    }
                                    return (
                                      <span key={cap.id} className={`badge bg-${cap.className} mx-2 p-1`}>
                                        <input
                                          type="checkbox"
                                          checked={checkCap(user, `${item.path}/${cap.path}`)}
                                          disabled={checkCapDisabled(user)}
                                          onChange={updateCaps.bind(null, user, `${item.path}/${cap.path}`)}
                                        />
                                        <span className={`${cap.fontClass} mx-2 fw-bolder`}>{cap.title}</span>
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                      </td>
                      <td className="align-middle">
                        {admin?.email.toLowerCase() !== user.email.toLowerCase() && (
                          <div>
                            {AppUtil.isUserHaveAccess("/home/admins/delete") && (
                              <AppButton
                                text=""
                                tooltipTitle={strings.removeAsAdmin}
                                className={"mdi mdi-delete btn btn-danger mx-2"}
                                type={"button"}
                                onClick={() => {
                                  deleteActionState.value = { user: user, showDialog: true };
                                }}
                              />
                            )}

                            {AppUtil.isUserHaveAccess("/home/admins/addEdit") && (
                              <AppButton
                                text=""
                                tooltipTitle={strings.addAllCaps}
                                className={"mdi mdi-checkbox-multiple-marked btn btn-success mx-2"}
                                type={"button"}
                                onClick={async () => {
                                  let allcaps: string[] = [];
                                  homeItems.forEach(async (item) => {
                                    caps.forEach(async (cap) => {
                                      allcaps.push(`${item.path}/${cap.path}`);
                                    });
                                  });
                                  updateAllCaps(user, allcaps);
                                }}
                              />
                            )}

                            {AppUtil.isUserHaveAccess("/home/admins/addEdit") && (
                              <AppButton
                                text=""
                                tooltipTitle={strings.deleteAllCaps}
                                className={"mdi mdi-delete-variant btn btn-primary mx-2"}
                                type={"button"}
                                onClick={() => {
                                  updateAllCaps(user, []);
                                }}
                              />
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }}
              />
            </tbody>
          </Table>
        </div>
        <WarningAlertDialog
          show={deleteActionState.value.showDialog}
          onCancel={() => {
            deleteActionState.value = (oldState: any) => {
              return { ...oldState, showDialog: false };
            };
          }}
          onConfirm={deleteAdmin}
          title={strings.alert.sureDelete}
        />
        <NewAdminDialog
          show={addNewAdminActionState.value.showDialog}
          onCancel={() => {
            addNewAdminActionState.value = (oldState: any) => {
              loadUsers();
              return { ...oldState, showDialog: false };
            };
          }}
          title={strings.admins.addNew}
        />
      </Col>

      <Col>
        <Pagination
          current={usersRef.current.currentPage}
          total={usersRef.current.totalPages}
          onPageChange={loadMoreUsers}
        />
      </Col>
    </Row>
  );
}
