export default class ArrayUtil {

    static removeAtIndex(array: [], index: number, copyArray: boolean = false, numberOfRemoves: number = 1): [] {
        array.splice(index, numberOfRemoves);
        if (copyArray) {
            return [...array];
        }
        return array;
    }

    static removeAndClone(array: [], index: number, numberOfRemoves: number = 1) {
        return ArrayUtil.removeAtIndex(array, index, true, numberOfRemoves);
    }

    static findByIdAndRemove(element: any, array: any[], clone = true) {
        const elementIndex = array.findIndex((value: any) => {
            return value._id === element._id;
        });
        if (elementIndex != null && elementIndex > -1) {
            array.splice(elementIndex, 1);
        }
        if (clone) {
            return [...array];
        }
        return array;
    }
}