import SweetAlert from "react-bootstrap-sweetalert";
import {strings} from "../translation/locale";

interface Props {
    title: string;
    onConfirm: () => void,
    onCancel: () => void,
    show: boolean;
}

export default function WarningAlertDialog(props: Props) {
    return <SweetAlert 
    title=""
    onConfirm={props.onConfirm} 
    onCancel={props.onCancel} 
    type='danger'
    show={props.show} 
    confirmBtnText={strings.delete} 
    cancelBtnText={strings.cancel} 
    showCancel={true} 
    openAnim={true} 
    closeAnim={true}
    confirmBtnBsStyle="danger"
    cancelBtnBsStyle="light"
    >
        {props.title}
    </SweetAlert>
}