import LocalizedStrings from "react-localization";

export const strings: any = new LocalizedStrings({
    ar: {
        pageNotFoundTitle: "صفحة غير موجودة",
        pageNotFoundDescription: "الصفحه التى تحاول الوصول لها غير موجوده.",
        returnHome: "الرجوع للرئيسية",
        show: "عرض",
        create: "إنشاء",
        save: "حفظ",
        edit: "تعديل",
        delete: "حذف",
        confirm: "موافق",
        cancel: "إلغاء",
        addNew: "إضافة جديد",
        search: "بحث",
        logout: "تسجيل الخروج",
        ok: "موافق",
        user: "مستخدم",
        close: "إغلاق",
        sureDelete: "هل أنت متأكد من حذف",
        contentOfComment: "التعليق",
        contentOfReply: "الرد على التعليق",
        by: "بواسطة",
        send: "إرسال",
        replyToComment: "إرسال رد على تعليق من:",
        replayToCommentPlaceholder: "إكتب رسالتك هنا",
        accessDenied: "غير مسموح لك بالوصول إلى هذه الصفحة",
        deleteAllCaps: "حذف جميع الصلاحيات",
        addAllCaps: "إعطاء جميع الصلاحيات",
        removeAsAdmin: "إزالة كمسؤول",
        areYouSure: "هل أنت متأكد",
        login: {
            loginTitle: "تسجيل الدخول للموقع",
            signIn: "تسجيل الدخول",
            rememberMe: "حفظ البيانات",
            forgetPassword: "نسيت كلمة المرور",
            dontHaveAccount: "ليس لديك حساب؟",
            signUp: "تسجيل مستخدم",
            email: "البريد الالكترونى",
            password: "كلمة المرور",
        },
        validation: {
            invalidEmail: "البريد الالكترونى غير صحيح",
            required: "حقل مطلوب",
            minFieldChars: "اقل عدد {0} حروف",
            minNumChars: "اقل رقم {0}",
            minArray: "أقل عدد عناصر {0}",
        },
        alert: {
            pleaseWait: "برجاء الانتظار...",
            emailSentForU: "تم إرسال بريد الكترونى اليك.",
            sureDelete: "هل ترغب فى إستكمال الحذف؟",
        },
        dashboard: {
            navigation: "تصفح",
            dashboard: "الرئيسية",
            copyrights: "جميع الحقوق محفوظة لتطبيق اوديوهات 2021",
            settings: "الضبط",
            backup: "نسخ احتياطي",
            teamMembers: "فريق العمل",
            authors: "المؤلفين",
            pages: "الصفحات",
            categories: "التصنيفات",
            users: "المستخدمين",
            books: "الكتب",
            bookComments: "التعليقات",
            timeSpent: "الاحصائيات",
            announcments: "الإعلانات",
            admins: "المديرين",
            series: "السلاسل",
            booksReorder: "ترتيب الكتب",
            files: "الملفات",
            filesManagment: "إدارة الملفات",
        },
        announcements: {
            announcement: "الإعلان",
            title: "عنوان الإعلان",
            publishDate: "موعد النشر",
            url: "رابط الإعلان",
            announcmentPoster: "بوستر الإعلان",
            addNew: "أضف إعلان جديد",
        },
        settings: {
            appSettings: "إعدادات التطبيق",
            androidVersion: "إصدار نسخة الأندرويد",
            iosVersion: "الإصدار الرئيسي للأيفون",
            iosBuildVersion: "الإصدار الفرعي للأيفون",
            blockedUsersLabel: "المستخدمين المحظورين",
            blcockedUsersPlaceholder: "أدخل البريد الالكترونى للمستخدم المراد حظره كل بريد في سطر جديد",
        },
        teamMembers: {
            name: "إسم العضو",
            photo: "الصورة",
            bio: "نبذة",
            order: "الترتيب",
            action: "ألاكشن",
            addNew: "إضافة عضو",
        },
        authors: {
            addNew: "إضافة مؤلف",
            name: "إسم المؤلف",
        },
        backup: {
            backupNow: "نسخ احتياطي الآن",
            fileName: "اسم الملف",
            fileSize: "حجم الملف",
            createdDate: "تاريخ الإنشاء",
            download: "تحميل",
            downloadToolTip: "تحميل نسخة احتياطية",
            restore: "استعادة",
            restoreTooltip: "استعادة النسخة الاحتياطية",
            uploadFile: "رفع ملف",
            restoreConfirm: "هل أنت متأكد من استعادة النسخة الاحتياطية؟",
            backupRestored: "تم استعادة النسخة الاحتياطية بنجاح",
            backupRestoreFailed: "فشل استعادة النسخة الاحتياطية",
        },
        pages: {
            title: "إسم الصفحة",
            content: "المحتوى",
            slug: "الإختصار",
            addNew: "إضافة صفحة",
        },
        categories: {
            label: "إسم التصنيف",
            icon: "الصورة",
            addNew: "أضف تصنيف",
        },
        users: {
            name: "إسم المستخدم",
            email: "البريد الالكترونى",
            subscribed: "مشترك",
            notSubscribed: "غير مشترك",
            editSubscription: "تعديل الاشتراك",
            registeredAt: "تاريخ التسجيل",
            subscribeUntil: "مشترك حتى",
            fromWP: "معلومات وردبريس",
            showInfo: "عرض",
            admin: "مدير",
            sendToRevenuecat: "إرسال إلى Revenuecat",
            changePassword: "تغيير كلمة المرور",
            changePasswordTooltip: "تغيير كلمة المرور",
            changePasswordConfirm: "هل أنت متأكد من تغيير كلمة المرور لهذا المستخدم؟",
            passwordChanged: "تم تغيير كلمة المرور بنجاح",
            passwordChangeFailed: "فشل تغيير كلمة المرور",
            pleseEnterTheNewPassword: "الرجاء إدخال كلمة المرور الجديدة",
            sendNotification: "إرسال إشعار",
            showUserInfoFromWordPress: "عرض معلومات المستخدم من وردبريس",
            showUserInfoFromWordPressTooltip: "عرض معلومات المستخدم من وردبريس",
            deleteUserAccount: "حذف حساب المستخدم",
            deleteUserAccountTooltip: "حذف حساب المستخدم",
            subscriptionInfo: "معلومات الاشتراك",
            userNotFound: "المستخدم غير موجود",
            order: "الطلب",
            status: "الحالة",
            latestPayment: "آخر عملية دفع",
            nextPayment: "الدفعة القادمة",
            paymentMethod: "طريقة الدفع",
            paymentDate: "تاريخ الدفع",
            paymentAmount: "مبلغ الدفع",
            paymentDetails: "تفاصيل الدفع",
            subscription: "الاشتراك",
            sendNotificationToUser: "إرسال إشعار إلى المستخدم",
            notificationContent: "محتوى الإشعار",
            optionalURL: "رابط ( اختياري )",
            missingUser: "المستخدم غير موجود",
            missingNotificationContent: "محتوى الإشعار غير موجود",
            notificationContentIdRequired: "محتوى الإشعار مطلوب",
            notificationSent: "تم إرسال الإشعار بنجاح",
            sureToDeleteUser: "هل أنت متأكد من حذف المستخدم؟",
            phoneNumber: "رقم الهاتف",
            autoRenewal: "التجديد التلقائي",
            active: "مفعل",
            notActive: "غير مفعل",
        },
        books: {
            title: "العنوان",
            subtitle: "العنوان الفرعى",
            photo: "الصورة",
            order: "الترتيب",
            free: "مجانى",
            category: "التصنيف",
            series: "السلسلة",
            publisherRatio: "نسبة الناشر %",
            publisher: "الناشر",
            author: "المؤلف",
            publishDate: "موعد النشر",
            action: "ألاكشن",
            addNew: "أضف كتاب",
            show: "مشاهدة",
            summary: "الملخص",
            youtubeId: "رقم قناة يوتيوب",
            audioFile: "الملف",
            showFile: "عرض الملف",
            download: "تحميل",
            upload: "رفع",
            fileUploaded: "تم رفع الملف",
            cutAudios: "بدء قص الملفات",
            isFree: "مجانى",
            isRecommended: "موصى به",
            isCutBook: "تم القص",
            cut: "قص",
            clipboardSuccess: "تم نسخ الرابط إلى الحافظة",
            showQRCode: "هل تود عرض الQR code الخاص بالرابط؟",
            generateSharingUrl: "إنشاء رابط المشاركة",
            plaeseChooseTheCategory: "برجاء اختيار التصنيف المراد ترتيب الكتب التابعة له",
        },
        bookComments: {
            content: "المحتوى",
            likes: "إعجاب",
            dislikes: "عدم إعجاب",
            reports: "تبليغ",
            replies: "الردود",
            book: "الكتاب",
            commentsCount: "عدد التعليقات",
            noComments: "لا يوجد تعليقات",
            oneComment: "تعليق واحد",
            twoComments: "تعليقان",
            comments: "تعليقات",
            comment: "تعليق",
            latestCommentDate: "آخر تعليق",
            clock: "الساعة",
            by: "بواسطة",
            reply: "رد",
            replyTo: "رد على",
            replyToComment: "رد على التعليق",
            replyToReply: "رد على الرد",
            repliesC: "ردود",
            noReplies: "لا يوجد ردود",
            oneReply: "رد واحد",
            twoReplies: "ردان",
            showReplies: "عرض الردود",
            addReply: "أضف رد",
            deleteReply: "حذف الرد",
            deleteComment: "حذف التعليق",
            sendReply: "أرسل رد",
            noLikes: "لا يوجد إعجابات",
            oneLike: "إعجاب واحد",
            twoLikes: "إعجابان",
            likesCount: "عدد الإعجابات",
            like: "إعجاب",
            likesC: "إعجابات",
            report: "تبليغ",
            reportsCount: "عدد التبليغات",
            noReports: "لا يوجد تبليغات",
            oneReport: "تبليغ واحد",
            twoReports: "تبليغان",
        },
        timeSpent: {
            bookTitle: "إسم الكتاب",
            bookAuthor: "مؤلف الكتاب",
            timeSpent: "دقائق الاستماع",
            exportExcel: "تصدير إكسيل",
            calc: "إحسب",
            fromDate: "التاريخ من",
            toDate: "التاريخ إلى",
        },
        messages: {
            mustUploadFile: "يجب رفع الملف اولا.",
            publishDateShouldBeAfterNow: "وقت النشر يجب أن يكون بعد الوقت الحالى.",
            nameShouldNotContainsSpace: "إسم الملف يجب الا يحتوى على مسافات.",
        },
        admins: {
            addNew: "أضف مدير جديد",
            add: "أضف",
            caps: "الصلاحيات",
            addAsAdmin: "أضف كمدير",
            admins: "المديرين",
            admin: "مدير",
        },
        series: {
            addNew: "أضف سلسلة جديدة",
            add: "أضف",
            series: "السلاسل",
            seriesTitle: "عنوان السلسلة",
            seriesAuthor: "مؤلف السلسلة",
            seriesPhoto: "صورة السلسلة",
            seriesSummary: "ملخص السلسلة",
            seriesOrder: "الترتيب",
            seriesAction: "ألاكشن",
            books: "الكتب",
        },
    },
});
