import { useEffect, useMemo, useRef } from "react";
import { useDebounce } from "use-debounce";
//import Pagination from "react-responsive-pagination";

import { appStore } from "../../data/store/AppStore";
import useComponentState from "../../hooks/useComponentState";
import HttpRequest from "../../data/network/HttpRequest";
import HttpService from "../../data/network/HttpService";

import { strings } from "../../translation/locale";
import { BookComment, BookCommentsResponse } from "../../data/model/BooksResponse";
// UI
import ToastManager from "../../util/ToastManager";
import { Alert, Form, Col, Row, Dropdown, Button } from "react-bootstrap";
import { IfCondition } from "react-ifloop-ts";
import DateTimeUtil from "../../util/DateTimeUtil";
import AppLoadingSpinner from "../../components/AppLoadingSpinner";
import DataTable from "react-data-table-component";
import WarningAlertDialog from "../../components/WarningAlertDialog";
import DialogUtil from "../../util/DialogUtil";

export default function BookCommentsScreen() {
  const loadingState = useComponentState({ isLoading: true, error: null });
  const commentsState = useComponentState([]);
  //const allCommentsRef = useRef<BookComment[]>();
  const allCommentsRef = useRef<any>({ limit: 10, comments: [], total: 0, currentPage: 1, totalPages: 0 });
  const commentsCountRef = useRef(0);
  const searchValueRef = useRef<string>("");
  const deleteActionState = useComponentState({ showDialog: false, comment: null });
  const searchFiledState = useComponentState("");
  const [searchText] = useDebounce(searchFiledState.value, 1000);

  const humanReadableRepliesCount = (count: number) => {
    switch (true) {
      case count <= 0:
        return strings.bookComments.noReplies;
      case count > 0 && count <= 1:
        return strings.bookComments.oneReply;
      case count > 1 && count <= 2:
        return strings.bookComments.twoReplies;
      case count > 2 && count < 11:
        return count + " " + strings.bookComments.repliesC;
      default:
        return count + " " + strings.bookComments.reply;
    }
  };
  const humanReadableLikesCount = (count: number) => {
    switch (true) {
      case count <= 0:
        return strings.bookComments.noLikes;
      case count > 0 && count <= 1:
        return strings.bookComments.oneLike;
      case count > 1 && count <= 2:
        return strings.bookComments.twoLikes;
      case count > 2 && count < 11:
        return count + " " + strings.bookComments.likesC;
      default:
        return count + " " + strings.bookComments.like;
    }
  };
  const humanReadableReportsCount = (count: number) => {
    switch (true) {
      case count <= 0:
        return strings.bookComments.noReports;
      case count > 0 && count <= 1:
        return strings.bookComments.oneReport;
      case count > 1 && count <= 2:
        return strings.bookComments.twoReports;
      case count > 2 && count < 11:
        return count + " " + strings.bookComments.reports;
      default:
        return count + " " + strings.bookComments.report;
    }
  };
  const columns = useMemo(() => {
    return [
      {
        name: null,
        width: "80px",
        cell: (row: BookComment) => authorAvatar(row.author),
      },
      {
        name: null,
        wrap: true,
        cell: (row: BookComment) => {
          return (
            <div className="w-100">
              <div className="d-flex">
                <div className="flex-grow-1">
                  {commentHeader(row)}
                  <div className="mb-3">{row.content}</div>
                  {commentFooter(row)}
                </div>
                <div className="align-self-center">{bookThumbnail(row.bookId)}</div>
              </div>
            </div>
          );
        },
      },
    ];
  }, []);

  // temp
  function filterComments(event: any) {
    const text = event.target.value || "";
    searchValueRef.current = text.trim();
    searchFiledState.value = text;
  }

  async function deleteFromState(reply: any, comment: any) {
    if (!comment) {
      return ToastManager.showSuccess("Missing comment to delete");
    }
    const originalState = [...commentsState.value];
    const foundedComment = originalState.find((c: any) => c._id === comment._id);
    if (!foundedComment) {
      return;
    }
    if (reply) {
      console.log("foundedComment", deleteActionState.value.recivedObject);
      const replies = [...foundedComment.replies];
      foundedComment.replies = replies.filter((c: any) => c._id !== reply._id);
    } else {
      originalState.filter((c: any) => c._id !== comment._id);
    }
    commentsState.value = originalState;
  }
  async function deleteComment() {
    const { endpoint, comment, reply } = deleteActionState.value.recivedObject;
    deleteActionState.value = (oldState: any) => {
      return { ...oldState, showDialog: false, comment: null };
    };

    // delete from server
    const request = new HttpRequest();
    request.method = "DELETE";
    request.url = `admin/v2/${endpoint}`;

    HttpService.send(request)
      .then(async (res) => {
        ToastManager.showSuccess(res.data.message);
        await deleteFromState(reply, comment);
      })
      .catch((e) => {
        ToastManager.showError(e.message);
      });
  }

  async function sendReplyToComment(message: any) {
    const request = new HttpRequest();
    request.method = "POST";
    request.url = message.endpoint;
    request.body = { content: message.content, replyTo: message.replyToId };

    HttpService.send(request)
      .then((res) => {
        window.location.reload();
      })
      .catch((e) => {
        ToastManager.showError(e.message);
      });
  }
  // end temp

  function loadBookComments() {
    const request = new HttpRequest();
    request.method = "GET";
    request.url = "admin/v2/books/comments";
    let page = allCommentsRef.current.currentPage;

    let query: any = { page: page, limit: allCommentsRef.current.limit };
    if (searchText !== "") {
      query["q"] = searchText;
    }
    request.queryParams = query;

    HttpService.send(request)
      .then((res) => {
        loadingState.value = { isLoading: false, error: null };
        const response = (res.data as BookCommentsResponse).response;

        allCommentsRef.current = response.comments;
        commentsState.value = response.comments;

        if (response.total > -1) {
          allCommentsRef.current.total = response.total;
          allCommentsRef.current.totalPages = Math.round(response.total / allCommentsRef.current.limit);
          if (commentsCountRef.current === 0) {
            commentsCountRef.current = response.total;
            appStore.update((state) => {
              state.screenHeaderName = strings.dashboard.users + " (" + response.total + ") " + strings.user;
            });
          }
        }
      })
      .catch((e) => {
        loadingState.value = { isLoading: false, error: e.message };
      });
  }
  function loadMoreComments(page: number) {
    console.log("loadMoreComments", page);
    allCommentsRef.current.currentPage = page;
    loadBookComments();
  }
  useEffect(() => {
    appStore.update((state) => {
      state.screenHeaderName = strings.dashboard.bookComments;
      state.showBackButton = false;
    });
  }, []);

  useEffect(() => {
    allCommentsRef.current = { limit: 10, offset: 0, total: 0, currentPage: 1, totalPages: 0, comments: [] };
    loadMoreComments(1);
  }, [searchText]);

  const authorAvatar = (author: any) => {
    if (!author) return null;
    let imageUrl = "https://wp.audiohatdar.com/wp-content/uploads/2021/06/person-placeholder.jpg";
    if (author.image) {
      if (typeof author.image === "object") {
        imageUrl = author.image.location ?? imageUrl;
      } else if (typeof author.image === "string") {
        imageUrl = author.image;
      }
    }

    return (
      <div
        className="align-self-start p-2"
        onClick={() => {
          DialogUtil.showImageDialogWithTitle(imageUrl, author.name);
        }}>
        <img
          className="img-responsive rounded-circle m-auto"
          style={{ width: "50px", height: "50px" }}
          src={imageUrl}
          alt={author.name}
        />
      </div>
    );
  };

  const bookThumbnail = (book: any) => {
    if (!book) return null;
    let imageUrl = "https://wp.audiohatdar.com/wp-content/uploads/2021/06/person-placeholder.jpg";
    if (typeof book.thumbnail === "object") {
      imageUrl = book.thumbnail.location;
    } else if (typeof book.thumbnail === "string") {
      imageUrl = book.thumbnail;
    }
    return (
      <div
        className="p-3 comment-book-thumbnail"
        onClick={() => {
          DialogUtil.showImageDialogWithTitle(imageUrl, book.title);
        }}>
        <p className="m-auto py-1 px-2">{book.title}</p>

        <img className="img-thumbnail" src={imageUrl} alt={book.title} />
      </div>
    );
  };
  const commentHeader = (comment: BookComment) => {
    if (!comment) return null;
    const { author } = comment;
    return (
      <div className="text-muted fw-lighter fs-075rem py-2">
        {dateWithIcon(comment.createdAt)}
        <div>
          {author.name} - <span> {author.email}</span>
        </div>
      </div>
    );
  };
  const commentReplyRow = (comment: BookComment, reply: any) => {
    if (!reply) return null;
    return (
      <div className="d-flex">
        <div className="align-self-start">{authorAvatar(reply.author)}</div>
        <div>
          {commentHeader(reply)}
          <div className="mb-3">{reply.content}</div>
          {commentReplyRowFooter(comment, reply)}
        </div>
      </div>
    );
  };
  const onDeleteComment = (comment: BookComment) => {
    deleteActionState.value = (oldState: any) => {
      return {
        ...oldState,
        showDialog: true,
        recivedObject: {
          comment: comment,
          endpoint: `books/comments/${comment._id}`,
          type: "comment",
        },
      };
    };
  };
  const onDeleteReply = (comment: BookComment, reply: any) => {
    deleteActionState.value = (oldState: any) => {
      return {
        ...oldState,
        showDialog: true,
        recivedObject: {
          comment: comment,
          endpoint: `books/comments/replies/${reply._id}`,
          reply: reply,
          type: "reply",
        },
      };
    };
  };
  const deleteButton = (callBack: any) => {
    return (
      <Button variant="outline-dark" size="sm" onClick={callBack}>
        <i className="mdi mdi-delete"> {strings.delete}</i>
      </Button>
    );
  };
  const addReplyButton = (obj: any, callBack: any) => {
    return (
      <Button variant="outline-success" size="sm" onClick={callBack}>
        <i className="mdi mdi-reply"> {strings.bookComments.sendReply}</i>
      </Button>
    );
  };
  const likesButton = (obj: any) => {
    return (
      <Button variant="outline-primary" size="sm">
        <i className="mdi mdi-heart">{humanReadableLikesCount(obj.likes?.length || 0)}</i>
      </Button>
    );
  };

  const reportsButton = (obj: any) => {
    return (
      <Button variant="outline-danger" size="sm">
        <i className="mdi mdi-emoticon-sad"> {humanReadableReportsCount(obj.reports?.length || 0)}</i>
      </Button>
    );
  };
  const onAddReplyToReply = (comment: BookComment, reply: any) => {
    DialogUtil.showSendCommentReply(comment, reply.author).then((result) => {
      if (result.message) {
        sendReplyToComment(result.message);
      }
    });
  };
  const commentReplyRowFooter = (comment: BookComment, reply: any) => {
    if (!reply) return null;
    return (
      <div className="comment-footer">
        {addReplyButton(reply, () => onAddReplyToReply(comment, reply))}
        {likesButton(reply)}
        {reportsButton(reply)}
        {deleteButton(() => onDeleteReply(comment, reply))}
      </div>
    );
  };

  const onAddReplyToComment = (comment: BookComment) => {
    DialogUtil.showSendCommentReply(comment, comment.author).then((result) => {
      if (result.message) {
        sendReplyToComment(result.message);
      }
    });
  };

  const commentFooter = (comment: BookComment) => {
    if (!comment) return null;
    const { replies } = comment;
    return (
      <div className="comment-footer">
        {addReplyButton(comment, () => onAddReplyToComment(comment))}
        {likesButton(comment)}
        {reportsButton(comment)}
        {replies && (
          // @ts-ignore
          <Dropdown className="d-inline mx-2" autoClose={false}>
            <Dropdown.Toggle variant="success" id="dropdown-autoclose-false">
              {humanReadableRepliesCount(replies.length)}
            </Dropdown.Toggle>

            {replies.length > 0 && (
              <Dropdown.Menu>
                {replies.map((reply: any) => {
                  return (
                    <Dropdown.ItemText key={reply._id} className="comment-reply-row">
                      {commentReplyRow(comment, reply)}
                      <Dropdown.Divider />
                    </Dropdown.ItemText>
                  );
                })}
              </Dropdown.Menu>
            )}
          </Dropdown>
        )}
        {deleteButton(() => onDeleteComment(comment))}
      </div>
    );
  };
  const dateWithIcon = (date: any) => {
    if (!date) return null;
    return (
      <div className="mb-1">
        <i className="mdi mdi-clock ml-2" />
        {DateTimeUtil.format(date, "YYYY-MM-DD HH:mm")}
      </div>
    );
  };

  return (
    <Row className={"flex-column justify-content-center"}>
      <IfCondition condition={loadingState.value.isLoading}>
        <Col className={"text-center"}>
          <AppLoadingSpinner />
        </Col>
      </IfCondition>

      <IfCondition condition={loadingState.value.error != null}>
        <Alert variant={"danger"}>{loadingState.value.error}</Alert>
      </IfCondition>

      <IfCondition condition={!loadingState.value.isLoading && loadingState.value.error == null}>
        <Col>
          <Form.Control
            placeholder={strings.search}
            className={"my-2 w-25"}
            value={searchFiledState.value}
            onChange={filterComments}
          />
        </Col>

        <Col>
          <DataTable
            columns={columns}
            data={commentsState.value}
            pagination
            paginationServer
            paginationTotalRows={commentsCountRef.current}
            paginationPerPage={allCommentsRef.current.limit}
            onChangePage={loadMoreComments}
            onChangeRowsPerPage={(newPerPage, page) => {
              allCommentsRef.current.limit = newPerPage;
              loadMoreComments(page);
            }}
            responsive
            sortIcon={<i className="mdi mdi-sort" />}
          />
        </Col>

        <WarningAlertDialog
          show={deleteActionState.value.showDialog}
          onCancel={() => {
            deleteActionState.value = (oldState: any) => {
              return { ...oldState, showDialog: false };
            };
          }}
          onConfirm={deleteComment}
          title={`${strings.sureDelete} ${
            deleteActionState.value.recivedObject?.type === "comment"
              ? strings.contentOfComment
              : strings.contentOfReply
          } ${strings.by}:  ${
            deleteActionState.value.recivedObject?.reply
              ? deleteActionState.value.recivedObject?.reply.author.name
              : deleteActionState.value.recivedObject?.comment.author.name
          }`}
        />
      </IfCondition>
    </Row>
  );
}
