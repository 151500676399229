import { useEffect } from "react";
import { appStore } from "../../data/store/AppStore";
import { strings } from "../../translation/locale";
import HttpService from "../../data/network/HttpService";
import HttpRequest from "../../data/network/HttpRequest";
import { Card, Col, Row } from "react-bootstrap";
import useComponentState from "../../hooks/useComponentState";
import { IfCondition } from "react-ifloop-ts";
import AppLoadingSpinner from "../../components/AppLoadingSpinner";
import ToastManager from "../../util/ToastManager";
import { ConfigResponse } from "../../data/model/ConfigResponse";
import { useFormik } from "formik";
import * as Yup from "yup";
import AppTextField from "../../components/AppTextField";
import AppButton from "../../components/AppButton";

export default function SettingsScreen() {
      const loading = useComponentState(true);
      const config: any = useComponentState({
            version_android: 0,
            version_ios: 0,
            version_ios_build: 0,
            blockedUsers: [],
      });

      function loadConfig() {
            const request = new HttpRequest();
            request.method = "GET";
            request.url = "admin/v2/config";
            HttpService.send(request)
                  .then((res) => {
                        loading.value = false;
                        const configResponse = res.data as ConfigResponse;
                        config.value = configResponse.config;
                  })
                  .catch((e) => {
                        loading.value = false;
                        ToastManager.showError(e.message);
                  });
      }

      useEffect(() => {
            loadConfig();

            appStore.update((s) => {
                  s.screenHeaderName = strings.dashboard.settings;
                  s.showBackButton = false;
            });
      }, []);

      const formik = useFormik({
            validateOnChange: false,
            validateOnBlur: false,
            enableReinitialize: true,
            initialValues: {
                  version_android: config.value.version_android,
                  version_ios: config.value.version_ios,
                  version_ios_build: config.value.version_ios_build,
                  blockedUsers: config.value.blockedUsers.join("\n"),
            },
            validationSchema: Yup.object().shape({
                  version_android: Yup.number()
                        .min(1, strings.formatString(strings.validation.minNumChars, 1))
                        .required(strings.validation.required),
                  version_ios: Yup.number()
                        .min(1, strings.formatString(strings.validation.minNumChars, 1))
                        .required(strings.validation.required),
                  version_ios_build: Yup.number()
                        .min(1, strings.formatString(strings.validation.minNumChars, 1))
                        .required(strings.validation.required),
                  blockedUsers: Yup.string().optional(),
            }),
            async onSubmit(values) {
                  // call web service.
                  const request = new HttpRequest();
                  request.url = "/admin/v2/config";
                  request.body = values;
                  request.method = "PUT";
                  loading.value = true;
                  HttpService.send(request)
                        .then((response) => {
                              loading.value = false;
                              ToastManager.showSuccess(response.data.message);
                        })
                        .catch((e) => {
                              loading.value = false;
                              ToastManager.showError(e.message);
                        });
            },
      });

      return (
            <Row className={"flex-column justify-content-center"}>
                  <IfCondition condition={loading.value}>
                        <Col className={"text-center"}>
                              <AppLoadingSpinner />
                        </Col>
                  </IfCondition>
                  <Col>
                        <Card>
                              <Card.Header className={"text-center"}>{strings.settings.appSettings}</Card.Header>
                              <Card.Body>
                                    <form onSubmit={formik.handleSubmit}>
                                          <AppTextField
                                                type={"number"}
                                                name={"version_android"}
                                                formik={formik}
                                                placeholder={strings.settings.androidVersion}
                                          />
                                          <AppTextField
                                                type={"number"}
                                                name={"version_ios"}
                                                formik={formik}
                                                placeholder={strings.settings.iosVersion}
                                          />
                                          <AppTextField
                                                type={"number"}
                                                name={"version_ios_build"}
                                                formik={formik}
                                                placeholder={strings.settings.iosBuildVersion}
                                          />
                                          <div className={"mb-3"}>
                                                <AppTextField
                                                      type={"text"}
                                                      name={"blockedUsers"}
                                                      formik={formik}
                                                      label={strings.settings.blockedUsersLabel}
                                                      placeholder={strings.settings.blcockedUsersPlaceholder}
                                                      as={"textarea"}
                                                      rows={9}
                                                />
                                          </div>
                                          <AppButton text={strings.save} type={"submit"} disabled={loading.value} />
                                    </form>
                              </Card.Body>
                        </Card>
                  </Col>
            </Row>
      );
}
