import { Link, useHistory } from "react-router-dom";
import { strings } from "../translation/locale";
import AppButton from "../components/AppButton";
import AppCache from "../data/cache/AppCache";

export default function AccessDeniedScreen(props: any) {
    const router = useHistory();
    return (
        <div>
            <div className="account-pages" />
            <div className="clearfix" />
            <div className="wrapper-page">
                <div className="text-center">
                    <Link to="/" className="logo">
                        <span>
                            Audio<span>Hat</span>
                        </span>
                    </Link>
                </div>
                <div className="m-t-40 card-box">
                    <div className="text-center">
                        <h3>عفوا {props.user.name}</h3>
                        <h4 className="font-bold m-b-0">{strings.accessDenied}</h4>
                    </div>
                    <div className="p-20">
                        <AppButton
                            text={strings.logout}
                            block={true}
                            className={"btn btn-danger fw-bolder"}
                            onClick={() => {
                                AppCache.getInstance().clear();
                                router.push("/auth/login");
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
