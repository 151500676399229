import useQueryParams from "../../../hooks/useQueryParams";
import {Alert, Card, Col, Form, Row} from "react-bootstrap";
import {IfCondition} from "react-ifloop-ts";
import AppLoadingSpinner from "../../../components/AppLoadingSpinner";
import {strings} from "../../../translation/locale";
import AppTextField from "../../../components/AppTextField";
import AppButton from "../../../components/AppButton";
import useComponentState from "../../../hooks/useComponentState";
import {useFormik} from "formik";
import * as Yup from "yup";
import HttpRequest from "../../../data/network/HttpRequest";
import HttpService from "../../../data/network/HttpService";
import ToastManager from "../../../util/ToastManager";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import React from "react";
import {Editor} from "@tinymce/tinymce-react";
import {appStore} from "../../../data/store/AppStore";

export default function AddEditPageScreen() {
    const queryParams = useQueryParams();
    const loading = useComponentState(false);
    const router = useHistory();
    const pageState = useComponentState({title: "", content: "", slug: ""});

    function loadPageDetails(pageId: any) {
        loading.value = true;
        const request = new HttpRequest();
        request.method = "GET";
        request.url = `admin/v2/pages/${pageId}`;
        HttpService.send(request).then((res) => {
            loading.value = false;
            pageState.value = {
                title: res.data.page.title, content: res.data.page.content, loadDetailsError: null
            };
        }).catch((e) => {
            loading.value = false;
            pageState.value = ((old: any) => {
                old.loadDetailsError = e.message;
                return {...old};
            })
        });
    }

    useEffect(() => {
        appStore.update((store) => {
            store.showBackButton = true;
        });

        if (queryParams.id) {
            // load page details
            loadPageDetails(queryParams.id);
        }
    }, [queryParams]);
    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        initialValues: {
            title: pageState.value.title
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().min(2, strings.formatString(strings.validation.minFieldChars, 2)).required(strings.validation.required)
        }),
        async onSubmit(values) {
            // call web service.
            const request = new HttpRequest();
            const data: any = {...values};
            data.content = pageState.value.content;

            if (queryParams.id) {
                // edit team member
                request.url = `admin/v2/pages/${queryParams.id}`;
                request.body = data;
                request.method = 'PUT';
                loading.value = true;
            } else {
                // add new team member.
                request.url = `admin/v2/pages`;
                request.body = data;
                request.method = 'POST';
                loading.value = true;
            }

            HttpService.send(request).then((response) => {
                loading.value = false;
                ToastManager.showSuccess(response.data.message);
                router.push("/home/pages");
            }).catch((e) => {
                loading.value = false;
                ToastManager.showError(e.message);
            });
        },
    });

    return <Row className={"flex-column justify-content-center"}>
        <IfCondition condition={loading.value}>
            <Col className={'text-center'}><AppLoadingSpinner/></Col>
        </IfCondition>
        <IfCondition condition={pageState.value.loadDetailsError != null}>
            <Alert variant={'danger'}>{pageState.value.loadDetailsError}</Alert>
        </IfCondition>
        <Col>
            <Card>
                <Card.Header className={"text-center"}>
                    {queryParams.id ? strings.edit : strings.addNew}
                </Card.Header>
                <Card.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <AppTextField type={'text'} name={'title'} formik={formik} placeholder={strings.pages.title}/>
                        <Form.Group>
                            <Form.Label>{strings.pages.content}</Form.Label>
                            <Editor
                                apiKey="3nc92dxbmv2bubhxvizd6sabkxnlbpemej9peekxhydtoz6l"
                                value={pageState.value.content}
                                init={{
                                    height: 500,
                                    menubar: false,
                                    directionality: 'rtl',
                                    plugins: [
                                        "advlist autolink lists link image charmap preview anchor",
                                        "visualblocks code",
                                        "media table paste code wordcount",
                                    ],
                                    toolbar:
                                        "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | image | media | link bullist numlist outdent indent | removeformat | help",
                                }}
                                onEditorChange={(content) => {
                                    pageState.value = {...pageState.value, content};
                                }
                                }
                            />
                        </Form.Group>
                        <AppButton text={strings.save} type={'submit'} disabled={loading.value}/>
                    </form>
                </Card.Body>
            </Card>
        </Col>
    </Row>
}